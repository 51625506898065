import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { User } from '../../types/User'
import { Contract } from '../../types/Contract'

export interface AuthState {
  user?: User | null
  userImpersonate?: User | null
  logout?: boolean
}

const initialState: AuthState = {
  user: null,
  userImpersonate: null,
  logout: true
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        user: action.payload,
        logout: false
      }
    },
    setUserImpersonate: (state, action: PayloadAction<AuthState>) => {
      return {
        ...state,
        userImpersonate: action.payload.userImpersonate,
        logout: false
      }
    },
    logout: (state) => {
      localStorage.removeItem('@lutocar-user-info')
      localStorage.removeItem('@lutocar-token')
      localStorage.removeItem('@lutocar-token-impersonate')
      localStorage.removeItem('@lutocar-current-contract')
      return {
        ...state,
        user: null,
        userImpersonate: null,
        logout: true
      }
    }
  }
})

export const { setUser, setUserImpersonate, logout } = authSlice.actions

export const selectUser = (state: RootState) => {
  return state.authentication.user!
}

export const selectActionSystem = (state: RootState) => {
  return state.authentication.user?.acoesSistema
}

export const userIsImpersonate = (state: RootState) => {
  return !!state.authentication.user?.identificadorUsuarioImpersonate
}

export const selectUserImpersonate = (state: RootState) =>
  state.authentication.userImpersonate

export const selectUserIsCityHall = (state: RootState) => {
  return state.authentication.user?.conta?.tipoConta === 'Prefeitura'
}

export const selectUserIsContractByMatriz = (state: RootState) => {
  return !!state.authentication.user?.contratos?.find(
    (cont: Contract) => cont.matriz
  )
}

export const checkLogout = (state: RootState) => state.authentication.logout

export default authSlice.reducer
