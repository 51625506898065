export function toFixed(num: number, fixed: number) {
  if (num === 0) {
    return '0'
  }
  const roundedNum = num?.toFixed(fixed)
  const [integer, decimals] = roundedNum.split('.')

  return fixed === 0
    ? integer
    : `${integer},${(decimals || '').padEnd(fixed, '0')}`
}
