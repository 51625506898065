import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { changePasswordSchema } from '../../utils/validations/user'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'
import { changePassword } from '../../app/api/authenticate'
import { useAppSelector } from '../../app/hooks'
import { selectUser } from '../../app/slices/authSlice'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/material'
import { ModalBase, Button } from '../index'
import { InputBase, StrenghtPassword } from '../index'

interface ChangePasswordData {
  senhaAtual: string
  novaSenha: string
  confirmarNovaSenha: string
}

interface ChangePasswordProps {
  open: boolean
  close: () => void
}

const ChangePassword = ({ open, close }: ChangePasswordProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [strongPassword, setStrongPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)
  const user = useAppSelector(selectUser)
  const { enqueueSnackbar } = useSnackbar()

  const { register, handleSubmit, formState } = useForm<ChangePasswordData>({
    resolver: yupResolver(changePasswordSchema)
  })

  const { errors } = formState

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const handleChangePassword: SubmitHandler<ChangePasswordData> = async (
    values
  ) => {
    if (passwordValid) {
      setLoading(true)
      try {
        const response = await changePassword({
          ...values,
          email: user?.email!
        })

        if (response.success) {
          enqueueSnackbar(response.message, {
            variant: 'success'
          })
          setIsOpen(false)
          close()
        } else {
          enqueueSnackbar(response.message, {
            variant: 'error'
          })
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        enqueueSnackbar(
          'Ocorreu um erro interno. Comunique o administrador do sistema!',
          {
            variant: 'error'
          }
        )
      }
    } else {
      enqueueSnackbar('Senha muito fraca', {
        variant: 'error'
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(handleChangePassword)}>
      <ModalBase
        title="Trocar senha"
        open={isOpen}
        isLoading={loading}
        fullWidth
        maxWidth="sm"
        toggle={() => {
          setIsOpen(false)
          close()
        }}
        footer={
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Button
              disabled={loading}
              color="gray"
              onClick={() => {
                setIsOpen(false)
                close()
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={loading}
              color="primary"
              type="submit"
              onClick={handleSubmit(handleChangePassword)}
            >
              Salvar
            </Button>
          </Box>
        }
      >
        <>
          <InputBase
            className="mt-3"
            id="senhaAtual"
            fullWidth
            size="small"
            label="Senha atual"
            autoComplete="off"
            isPassword={true}
            errorContent={errors.senhaAtual as FieldError}
            {...register('senhaAtual')}
          />
          <InputBase
            className="mt-3"
            id="novaSenha"
            fullWidth
            size="small"
            label="Nova senha"
            autoComplete="off"
            isPassword={true}
            errorContent={errors.novaSenha as FieldError}
            {...register('novaSenha', {
              onChange: (e) => {
                setStrongPassword(e.target.value)
              }
            })}
          />
          <StrenghtPassword
            value={strongPassword}
            validate={(validatePassword: boolean) =>
              setPasswordValid(validatePassword)
            }
          />
          <InputBase
            className="mt-3"
            id="confirmarNovaSenha"
            fullWidth
            size="small"
            label="Confirmação nova senha"
            autoComplete="off"
            isPassword={true}
            errorContent={errors.confirmarNovaSenha as FieldError}
            {...register('confirmarNovaSenha')}
          />
        </>
      </ModalBase>
    </form>
  )
}

export default ChangePassword
