import { AxiosError } from 'axios'
import Response from '../api/Response'
import api from './api'
import { SweepOverviewPoint } from '../../types/SweepOverview'

interface QueryTrackerSweepOverview {
  codigoContrato?: number
  codigoSetor?: number
  CodigosRastreadores?: number[]
  codigoTurno?: number
  HoraInicial?: string
  HoraFinal?: string
  dataExecucao?: string
  query: string
  exibirUltimoPontoRoadSnap: boolean
}

export const listAllSpecialServiceSweep = async ({
  codigoContrato,
  dataExecucao,
  query = '',
  exibirUltimoPontoRoadSnap
}: QueryTrackerSweepOverview): Promise<Response<any>> => {
  try {
    const iscodigoContrato = !!codigoContrato

    const params = `${
      iscodigoContrato ? `CodigoContrato=${codigoContrato}` : ''
    } ${`&DataExecucao=${dataExecucao}`}&Query=${query}&ExibirUltimoPontoRoadSnap=${exibirUltimoPontoRoadSnap}`.trim()

    const response = await api.get(`SinoticoServicosEspeciais/Listar?${params}`)

    return Response.fromDataPagination(
      response.data.data as any[],
      response.status,
      response.data.campos,
      response.data.count,
      response.data.ordenacaoAtual,
      response.data.count,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const readHistorySpecialServiceByTrackerCode = async (
  trackerCode: number,
  startDate: string,
  endDate: string,
  speedLimit: number
): Promise<Response<SweepOverviewPoint[]>> => {
  try {
    const params =
      `?CodigoRastreador=${trackerCode}&DataInicial=${startDate}&DataFinal=${endDate}&VelocidadeLimite=${speedLimit}
    `.trim()

    const response = await api.get(
      `SinoticoServicosEspeciais/BuscarHistoricoRastreador${params}`
    )

    return Response.fromData(
      response.data.data as SweepOverviewPoint[],
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
