import { GridColDef } from '@mui/x-data-grid'
import { BateryPercentIcon } from '../../utils/getBateryPercentIcon'
import { FilterHeaderI } from '../sweepOverview'
import { Option } from '../../types/Option'
import FilterHeader from '../sweepOverview/components/filterHeader'
import FilterHeaderSelect from './components/filterHeaderSelect'

export type DataGridI = GridColDef & {
  hasFilterHeader?: boolean
  keyFilterHeader?: string
  isSelect?: boolean
}

const columnsObject: DataGridI[] = [
  {
    field: 'idRastreador',
    headerName: 'ID Rastreador',
    sortable: true,
    disableColumnMenu: true,
    minWidth: 150,
    maxWidth: 150
  },
  {
    field: 'nivelDaBateria',
    headerName: 'Nível da bateria',
    sortable: true,
    disableColumnMenu: true,
    hasFilterHeader: true,
    keyFilterHeader: 'batery',
    isSelect: true,
    minWidth: 180,
    maxWidth: 250,
    renderCell: (cellValue) => {
      const percentualBateriaCritica = cellValue.row.percentualBateriaCritica
      const percentualBateriaAlerta = cellValue.row.percentualBateriaAlerta
      return (
        <>
          <BateryPercentIcon
            percent={cellValue.value}
            percentToAlert={percentualBateriaCritica}
            percentToWarning={percentualBateriaAlerta}
          />
          {cellValue.value ? `${cellValue.value}%` : `00 %`}
        </>
      )
    }
  },
  {
    field: 'horarioGeracaoUltimoPonto',
    headerName: 'Horário geração último ponto',
    sortable: true,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 250,
    maxWidth: 250
  },
  {
    field: 'specialServices',
    headerName: 'Serviços Especiais',
    sortable: true,
    disableColumnMenu: true,
    minWidth: 250,
    align: 'left',
    headerAlign: 'left'
  }
]

export const setFilterColumnComplete = (
  filterHeader: any,
  setFilterHeader: (e: FilterHeaderI) => void
) => {
  const handleSetFilterHeader = (key: string, option: Option) => {
    filterHeader[key].selected = option
    setFilterHeader(filterHeader)
  }
  return columnsObject.map((colun) => {
    let renderHeader = null
    if (colun.hasFilterHeader && !colun.isSelect) {
      renderHeader = () => (
        <FilterHeader
          headerName={colun.headerName!}
          options={filterHeader[colun.keyFilterHeader!].options}
          selected={filterHeader[colun.keyFilterHeader!].selected}
          onChange={(option) => {
            handleSetFilterHeader(colun.keyFilterHeader!, option)
          }}
        />
      )
    } else if (colun.hasFilterHeader && colun.isSelect) {
      renderHeader = () => (
        <FilterHeaderSelect
          headerName={colun.headerName!}
          selected={filterHeader[colun.keyFilterHeader!].selected}
          onChange={(option) => {
            if (!option) {
              handleSetFilterHeader(colun.keyFilterHeader!, {
                label: 'Todos',
                value: 0
              })
            } else {
              handleSetFilterHeader(colun.keyFilterHeader!, option)
            }
          }}
        />
      )
    } else if (colun.renderHeader) {
      renderHeader = () => colun.renderHeader
    }

    return {
      ...colun,
      flex: 1,
      minWidth: colun.minWidth ? colun.minWidth : 200,
      sortable: colun.sortable ?? true,
      renderCell: colun.renderCell,
      renderHeader: renderHeader
    }
  })
}
