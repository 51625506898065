import { useState, useEffect, useRef } from 'react'
import { Button, MapArea, SpinnerLoading } from '../index'
import {
  Groups,
  KmDrawn,
  MapContainer,
  MapSearch,
  SearchBar,
  ToolsList,
  Wrapper
} from './styles'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import L from 'leaflet'
import { Map, PolylineType } from '@inlog/inlog-maps/lib'
import { mapDrawHooks, mapHooks } from '../../map/index'
import {
  ContentCopy,
  ContentCut,
  Delete,
  NearMe,
  Polyline,
  Search,
  Straighten,
  Undo
} from '@mui/icons-material'
import { v4 as uuidv4 } from 'uuid'
import { useSnackbar } from 'notistack'
import { getCoordinateByAddress } from '../../app/api/geocode'
import { useAppSelector } from '../../app/hooks'
import { selectCurrentContract } from '../../app/slices/contractSlice'
import { contrastPallete } from '../../utils/colorPallete'
import GroupComponent from './groupDraw'

interface ShapeTwins {
  latLongClick: number[]
  index: number
  originUuid: string | number
  destinationUuid: string | number | null
  sequences: number[]
  menuChoice: 'Twin' | 'Parallel' | null
}

interface ShapesProps {
  groupsTwins: ShapeTwins[][]
  polylines: {
    uuid: string | number
    latlngs: number[][]
  }[]
}

interface MapDrawProps {
  initialData?: ShapesProps
  getMapData: (data: ShapesProps) => void
  wrapperHeight?: string | undefined
}

const MapDraw = ({ initialData, getMapData, wrapperHeight }: MapDrawProps) => {
  let upperLimit = 60
  const lowerLimit = 10
  const { enqueueSnackbar } = useSnackbar()
  const currentContract = useAppSelector(selectCurrentContract)

  const enableColor = '#009ACA'
  const disableColor = '#000000'

  const [searchAddress, setSearchAddress] = useState('')
  const [lockSearchAddress, setLockSearchAddress] = useState(true)
  const [loadingSearchAddress, setLoadingSearchAddress] = useState(false)

  const [map, setMap] = useState<Map | null>(null)
  const [historic, setHistoric] = useState<any>([])
  const [uuidEditing, setUuidEditing] = useState('')
  const [activeTool, setActiveTool] = useState('navigation')
  const [countEventKey, setCountEventKey] = useState(0)

  const [kmDrawn, setKmDrawn] = useState(0)

  const [groupsTwins, setGroupsTwins] = useState<any[]>([])

  const allUuids =
    historic.length > 1 ? Object.keys(historic[historic.length - 2]) : []

  const colorByIndex = (index: number) => {
    const colorPallete = contrastPallete.flat()
    const colorsLenght = colorPallete.length
    let color = '#'

    const page = Math.floor(index / colorsLenght)
    const colorIndex =
      index > colorsLenght ? index - page * colorsLenght : index
    color += `${colorPallete[colorIndex]}`

    return color
  }

  interface ColorPair {
    dark: string
    light: string
  }

  const colorMap: { [key: string]: ColorPair } = {
    red: { dark: '#8B0000', light: '#FF6347' },
    darkRed: { dark: '#A52A2A', light: '#FF7F7F' },
    orange: { dark: '#FF4500', light: '#FFA07A' },
    darkOrange: { dark: '#FF8C00', light: '#FFD700' },
    gold: { dark: '#DAA520', light: '#FFD700' },
    green: { dark: '#006400', light: '#98FB98' },
    darkGreen: { dark: '#228B22', light: '#00FF7F' },
    lightGreen: { dark: '#32CD32', light: '#90EE90' },
    cyan: { dark: '#008B8B', light: '#E0FFFF' },
    teal: { dark: '#008080', light: '#40E0D0' },
    blue: { dark: '#00008B', light: '#87CEFA' },
    darkBlue: { dark: '#0000CD', light: '#6495ED' },
    lightBlue: { dark: '#4169E1', light: '#ADD8E6' },
    purple: { dark: '#4B0082', light: '#DA70D6' },
    indigo: { dark: '#4B0082', light: '#9A32CD' },
    pink: { dark: '#C71585', light: '#FFB6C1' },
    deepPink: { dark: '#FF1493', light: '#FF69B4' },
    brown: { dark: '#8B4513', light: '#F4A460' },
    maroon: { dark: '#800000', light: '#C71585' },
    navy: { dark: '#000080', light: '#4682B4' }
  }

  const colorKeys = Object.keys(colorMap)
  const guideColorMap: { [key: string]: ColorPair } = {}
  const usedColors = new Set<string>()

  function hashCode(str: string): number {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash |= 0 // Convert to 32bit integer
    }
    return Math.abs(hash)
  }

  function getGuideColorPair(guideId: string): ColorPair {
    if (guideColorMap[guideId]) {
      return guideColorMap[guideId]
    }

    let index = hashCode(guideId) % colorKeys.length

    // Encontre uma cor que ainda não foi usada
    while (usedColors.has(colorKeys[index])) {
      index = (index + 1) % colorKeys.length
    }

    const colorKey = colorKeys[index]
    usedColors.add(colorKey)
    guideColorMap[guideId] = colorMap[colorKey]

    return colorMap[colorKey]
  }

  useEffect(() => {
    if (currentContract) {
      mapHooks
        .loadMap(
          'map-draw',
          currentContract?.chaveGoogle?.trim()
            ? String(currentContract?.chaveGoogle)
            : null
        )
        .then((currentMap: Map) => {
          setMap(currentMap)
        })
    }
  }, [currentContract])

  const handleSaveHistoric = (uuid: string, type: string) => {
    // TODO: Parece não interferir na performance, mas ver formas mais edicientes.
    setHistoric((prevHistoric: any) => {
      switch (type) {
        case 'polyline':
          const polylinePath = map?.getPolylinePath(
            'polyline',
            (object: { uuid: string }) => object.uuid === uuid
          )
          let addedIndex = -1
          if (
            polylinePath?.length! >
            prevHistoric[prevHistoric.length - 1][uuid]?.latlng.length
          ) {
            addedIndex = polylinePath?.findIndex(
              (latlng: number[], index: number) => {
                const latlngOld =
                  prevHistoric[prevHistoric.length - 1][uuid]?.latlng[index]
                if (latlngOld !== undefined) {
                  if (
                    latlng[0] !== latlngOld[0] &&
                    latlng[1] !== latlngOld[1]
                  ) {
                    return index
                  }
                } else {
                  return index
                }
              }
            )!
          }
          return [
            ...prevHistoric,
            {
              ...prevHistoric[prevHistoric.length - 1],
              [uuid]: {
                patch:
                  prevHistoric[prevHistoric.length - 1][uuid]?.patch.map(
                    (patch: any) => {
                      if (
                        addedIndex !== -1 &&
                        addedIndex <= patch.sequences[0]
                      ) {
                        return {
                          ...patch,
                          sequences: patch.sequences.map(
                            (item: number) => item + 1
                          )
                        }
                      }
                      return patch
                    }
                  ) ?? [],
                type: type,
                latlng: polylinePath
              }
            }
          ]
      }
    })
  }

  const selectShape = (uuid: string, type: string) => {
    setUuidEditing(uuid)
    setGroupsTwins((prevGroupTwins) => {
      mapDrawHooks.ResetShapeColor(
        map,
        disableColor,
        prevGroupTwins.flat().map((item) => item.destinationUuid)
      )
      switch (type) {
        case 'polyline':
          const existDestination = prevGroupTwins
            .flat()
            .findIndex((group) => group.destinationUuid === uuid)

          if (existDestination === -1) {
            mapDrawHooks.AlterPolylineColor(map, uuid, enableColor)
          }
          break
      }
      return prevGroupTwins
    })
  }

  const stateRef = useRef(activeTool)

  useEffect(() => {
    stateRef.current = activeTool
  }, [activeTool, historic])

  const handleUpdateShape = (uuid: string, type: string) => {
    mapDrawHooks.RemoveMapEventClick(map)
    switch (stateRef.current) {
      case 'polyline':
        mapDrawHooks.RemoveMapEventClick(map)
        mapDrawHooks.AddMapEventClick(map, (updateEvent) => {
          mapDrawHooks.UpdatePolyline(map, uuid, updateEvent.latlng)
          handleSaveHistoric(uuid, 'polyline')
        })
        mapDrawHooks.AddEventPolyline(map, uuid, () => {
          handleSaveHistoric(uuid, 'polyline')
          handleUpdateShape(uuid, 'polyline')
        })
        break
      case 'navigation':
        handleSaveHistoric(uuid, 'polyline')
        break
    }
    selectShape(uuid, type)
  }

  const handleAddSequencePass = (uuid: string, destinationUuid: string) => {
    setHistoric((prevHistoric: any) => {
      const currentHistoric = prevHistoric[prevHistoric.length - 1][uuid]
      currentHistoric.patch[currentHistoric.patch.length - 1].destinationUuid =
        destinationUuid

      return prevHistoric
    })
  }

  const handleDestinationPendence = (uuidDestination: string) => {
    setHistoric((prevHistoric: any) => {
      const uuidOriginPendence = Object.keys(
        prevHistoric[prevHistoric.length - 1]
      ).find((uuid) =>
        prevHistoric[prevHistoric.length - 1][uuid].patch.find(
          (patch: ShapeTwins) => patch.destinationUuid === null
        )
      )
      const isMenuTwin = Object.keys(
        prevHistoric[prevHistoric.length - 1]
      ).find((uuid) =>
        prevHistoric[prevHistoric.length - 1][uuid].patch.find(
          (patch: ShapeTwins) => patch.menuChoice === 'Twin'
        )
      )
      const isMenuParallel = Object.keys(
        prevHistoric[prevHistoric.length - 1]
      ).find((uuid) =>
        prevHistoric[prevHistoric.length - 1][uuid].patch.find(
          (patch: ShapeTwins) => patch.menuChoice === 'Parallel'
        )
      )

      if (isMenuTwin) {
        if (uuidOriginPendence === uuidDestination) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          changeActiveTool('navigation')
          enqueueSnackbar(
            'Não é possível selecionar o trecho de sequência na mesma forma do trecho de transição!',
            {
              variant: 'warning'
            }
          )
        }

        if (
          uuidOriginPendence !== uuidDestination &&
          uuidOriginPendence !== undefined
        ) {
          handleAddSequencePass(uuidOriginPendence!, uuidDestination)
        }
      }

      if (isMenuParallel) {
        if (uuidOriginPendence === uuidDestination) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          changeActiveTool('navigation')
          enqueueSnackbar(
            'Não é possível selecionar a mesma forma da primeira guia!',
            {
              variant: 'warning'
            }
          )
        }

        if (
          uuidOriginPendence !== uuidDestination &&
          uuidOriginPendence !== undefined
        ) {
          handleAddSequencePass(uuidOriginPendence!, uuidDestination)
        }
      }

      return [...prevHistoric]
    })
  }

  const handleAddPatchPass = (uuid: string, latlng: number[]) => {
    setHistoric((prevHistoric: any) => {
      let pointIndex = prevHistoric[prevHistoric.length - 1][
        uuid
      ].latlng.findIndex(
        (item: number[]) => item[0] === latlng[0] && item[1] === latlng[1]
      )

      const existsPendence = Object.keys(
        prevHistoric[prevHistoric.length - 1]
      ).find((uuidMap) =>
        prevHistoric[prevHistoric.length - 1][uuidMap].patch.find(
          (patch: ShapeTwins) => patch.destinationUuid === null
        )
      )

      const path = map?.getPolylinePath(
        'polyline',
        (object: { uuid: string }) => object.uuid === uuid
      )!

      let newPatch = null

      const isTransitionPoint = prevHistoric[prevHistoric.length - 1][
        uuid
      ].patch.find((item: ShapeTwins) => item.sequences.includes(pointIndex))
      // TODO: Melhorar e verificar se é só essa validação mesmo
      const isParallel = !!prevHistoric[prevHistoric.length - 1][
        uuid
      ].patch.find((item: any) => item?.menuChoice === 'Parallel')

      const destinationParellel =
        Object.values(prevHistoric[prevHistoric.length - 1]).filter(
          (el: any) => {
            return el?.patch.find(
              (pat: any) =>
                pat.destinationUuid === uuid && pat.menuChoice === 'Parallel'
            )
          }
        ).length > 0

      if (isParallel || destinationParellel) {
        enqueueSnackbar(
          'Não é possível gerar sobreposição de formas em guias paralelas!',
          {
            variant: 'error'
          }
        )
      }

      if (isTransitionPoint) {
        enqueueSnackbar(
          'Não é possível gerar ponto de transição a partir de um ponto de transição!',
          {
            variant: 'warning'
          }
        )
      }

      let pointsInserteds = 1

      if (pointIndex === -1) {
        pointIndex = mapHooks.findIndex(path, latlng) + 1
        pointsInserteds = 2
        path.splice(pointIndex, 0, latlng)
      }

      upperLimit = 30

      if (
        path.length > 1 &&
        !isTransitionPoint &&
        !existsPendence &&
        !isParallel &&
        !destinationParellel
      ) {
        if (pointIndex === path.length - 1) {
          const newLatlng = mapDrawHooks.calculateCoordinates(
            path[path.length - 1],
            path[path.length - 2],
            upperLimit
          )
          path.splice(path.length - 1, 0, newLatlng)
          newPatch = [pointIndex, pointIndex + 1]
        } else if (pointIndex === 0) {
          const newLatlng = mapDrawHooks.calculateCoordinates(
            path[0],
            path[1],
            upperLimit
          )
          path.splice(1, 0, newLatlng)
          newPatch = [pointIndex, pointIndex + 1]
        } else if (pointIndex >= 1) {
          const newLatlng1 = mapDrawHooks.calculateCoordinates(
            path[pointIndex],
            path[pointIndex + 1],
            upperLimit / 2
          )
          const newLatlng2 = mapDrawHooks.calculateCoordinates(
            path[pointIndex],
            path[pointIndex - 1],
            upperLimit / 2
          )
          const newLatlng3 = mapDrawHooks.calculateCoordinates(
            path[pointIndex],
            path[pointIndex - 1],
            upperLimit
          )

          const angle = mapDrawHooks.calculateAngle(
            path[pointIndex - 1],
            path[pointIndex],
            path[pointIndex + 1]
          )

          if (angle < 90) {
            path.splice(pointIndex, 0, newLatlng3)
            newPatch = [pointIndex, pointIndex + 1]
          } else {
            path.splice(pointIndex, 1)
            path.splice(pointIndex, 0, newLatlng1)
            path.splice(pointIndex, 0, newLatlng2)
            newPatch = [pointIndex, pointIndex + 1]
          }
        }

        if (pointIndex !== -1) {
          const listIndex = Object.keys(prevHistoric[prevHistoric.length - 1])
            .map(
              (uuidMap) => prevHistoric[prevHistoric.length - 1][uuidMap].patch
            )
            .flat()
            .sort((a, b) => a.index - b.index)

          prevHistoric = [
            ...prevHistoric,
            {
              ...prevHistoric[prevHistoric.length - 1],
              [uuid]: {
                patch: newPatch
                  ? [
                      ...prevHistoric[prevHistoric.length - 1][
                        uuid
                      ]?.patch?.map((patch: ShapeTwins) => {
                        if (pointIndex <= patch.sequences[0]) {
                          return {
                            ...patch,
                            sequences: patch.sequences.map(
                              (item: number) => item + pointsInserteds
                            ),
                            latLongClick: latlng
                          }
                        }
                        return patch
                      }),
                      {
                        index:
                          listIndex.length === 0
                            ? 1
                            : listIndex[listIndex.length - 1].index + 1,
                        destinationUuid: null,
                        sequences: newPatch,
                        latLongClick: latlng,
                        menuChoice: null
                      }
                    ]
                  : prevHistoric[prevHistoric.length - 1][uuid]?.patch,
                type: 'polyline',
                latlng: path
              }
            }
          ]
        }

        if (pointIndex >= 0) {
          mapDrawHooks.RemoveAllPolylines(map)
          Object.keys(prevHistoric[prevHistoric.length - 1]).map(
            (uuidMap: string) => {
              mapDrawHooks.RenderPolyline(
                map,
                uuidMap,
                prevHistoric[prevHistoric.length - 1][uuidMap].latlng,
                (e) => {
                  console.log('OPA')
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  handleClickVertex(uuidMap, e.latlng)
                  setActiveTool((prevActiveTool) => {
                    if (prevActiveTool !== 'cut') {
                      return 'polyline'
                    } else {
                      return 'cut'
                    }
                  })
                  setUuidEditing(uuidMap)
                  handleUpdateShape(uuidMap, 'polyline')
                  // TODO: Esse cara ve se tem pendente destionation
                  handleDestinationPendence(uuidMap)
                  setHistoric((prevHistoricUpdate: any) => {
                    return [...prevHistoricUpdate]
                  })
                }
              )
              mapDrawHooks.AddRightClickEventPolyline(map, uuidMap, (e) =>
                handleAddPatchPass(uuidMap, e.latlng)
              )
              mapDrawHooks.AddEventPolyline(map, uuidMap, () => {
                handleSaveHistoric(uuidMap, 'polyline')
                handleUpdateShape(uuidMap, 'polyline')
              })
            }
          )
          selectShape(uuid, 'polyline')
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          changeActiveTool('navigation')
        }
      }
      return prevHistoric
    })
  }

  const handleRemoveVertexPolyline = (uuidVertex: string, latlng: number[]) => {
    setHistoric((prevHistoric: any) => {
      const newHistoric = prevHistoric[prevHistoric.length - 1][uuidVertex]

      const exitsVertex = newHistoric.latlng.find((item: number[]) => {
        if (item[0] === latlng[0] && item[1] === latlng[1]) {
          return item
        }
      })

      const removedIndex = newHistoric.latlng.findIndex(
        (item: number[]) => item[0] === latlng[0] && item[1] === latlng[1]
      )

      if (exitsVertex) {
        let deletedVertex: any = null
        if (
          !!newHistoric.patch.find(
            (el: { menuChoice: string }) => el.menuChoice === 'Parallel'
          )
        ) {
          deletedVertex = {
            ...prevHistoric[prevHistoric.length - 1],
            [uuidVertex]: {
              ...newHistoric,
              patch: []
            }
          }
        } else {
          deletedVertex = {
            ...prevHistoric[prevHistoric.length - 1],
            [uuidVertex]: {
              ...newHistoric,
              patch: newHistoric.patch
                .filter((patch: any) => {
                  if (!patch.sequences.includes(removedIndex)) {
                    return patch
                  }
                })
                .map((patch: any) => {
                  if (removedIndex < patch.sequences[0]) {
                    return {
                      ...patch,
                      sequences: patch.sequences.map((item: number) => item - 1)
                    }
                  }
                  return patch
                }),
              latlng: newHistoric.latlng.filter((item: number[]) => {
                if (item[0] !== latlng[0] || item[1] !== latlng[1]) {
                  return item
                }
              })
            }
          }
        }

        mapDrawHooks.RemoveAllPolylines(map)
        mapDrawHooks.RemoveMapEventClick(map)
        setActiveTool('navigation')
        Object.keys(deletedVertex).map((uuid) => {
          switch (deletedVertex[uuid as keyof typeof deletedVertex].type) {
            case 'polyline':
              mapDrawHooks.RenderPolyline(
                map,
                uuid,
                deletedVertex[uuid].latlng,
                (e) => {
                  setActiveTool((prevActiveTool) => {
                    if (prevActiveTool !== 'cut') {
                      return 'polyline'
                    } else {
                      return 'cut'
                    }
                  })
                  setUuidEditing(uuid)
                  handleDestinationPendence(uuid)
                  handleUpdateShape(uuid, 'polyline')
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  handleClickVertex(uuid, e.latlng)
                }
              )
              mapDrawHooks.AddRightClickEventPolyline(map, uuid, (e) =>
                handleAddPatchPass(uuid, e.latlng)
              )
              mapDrawHooks.AddEventPolyline(map, uuid, () => {
                handleSaveHistoric(uuid, 'polyline')
                handleUpdateShape(uuid, 'polyline')
              })
              break
          }
        })

        console.log('[...prevHistoric, deletedVertex]', [
          ...prevHistoric,
          deletedVertex
        ])

        return [...prevHistoric, deletedVertex]
      } else {
        return prevHistoric
      }
    })
  }

  const handleCutVertexPolyline = (uuidVertex: string, latlng: number[]) => {
    setHistoric((prevHistoric: any) => {
      const newHistoric = prevHistoric[prevHistoric.length - 1][uuidVertex]

      const exitsVertex = newHistoric.latlng.find((item: number[]) => {
        if (item[0] === latlng[0] && item[1] === latlng[1]) {
          return item
        }
      })

      const vertexIndex = newHistoric.latlng.findIndex(
        (item: number[]) => item[0] === latlng[0] && item[1] === latlng[1]
      )

      if (
        exitsVertex &&
        vertexIndex !== 0 &&
        vertexIndex !== newHistoric.latlng.length - 1
      ) {
        const newUuid = uuidv4()
        const newPolyline = newHistoric.latlng.filter(
          (_: null, index: number) => index >= vertexIndex
        )
        const currentPolyline = newHistoric.latlng.filter(
          (_: null, index: number) => index <= vertexIndex
        )

        const cutShape = {
          ...prevHistoric[prevHistoric.length - 1],
          [newUuid]: {
            type: 'polyline',
            patch: newHistoric.patch
              .filter((patch: ShapeTwins) => patch.sequences[0] >= vertexIndex)
              .map((patch: ShapeTwins) => {
                return {
                  ...patch,
                  sequences: patch.sequences.map((item: number) => {
                    item = item - currentPolyline.length + 1
                    return item
                  })
                }
              }),
            latlng: newPolyline
          },
          [uuidVertex]: {
            ...newHistoric,
            patch: newHistoric.patch.filter(
              (patch: ShapeTwins) => patch.sequences[1] <= vertexIndex
            ),
            latlng: currentPolyline
          }
        }

        mapDrawHooks.RemoveAllPolylines(map)
        mapDrawHooks.RemoveMapEventClick(map)
        setActiveTool('navigation')
        Object.keys(cutShape).map((uuid) => {
          switch (cutShape[uuid as keyof typeof cutShape].type) {
            case 'polyline':
              mapDrawHooks.RenderPolyline(
                map,
                uuid,
                cutShape[uuid].latlng,
                (e) => {
                  setActiveTool((prevActiveTool) => {
                    if (prevActiveTool !== 'cut') {
                      return 'polyline'
                    } else {
                      return 'cut'
                    }
                  })
                  setUuidEditing(uuid)
                  handleDestinationPendence(uuid)
                  handleUpdateShape(uuid, 'polyline')
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  handleClickVertex(uuid, e.latlng)
                }
              )
              mapDrawHooks.AddRightClickEventPolyline(map, uuid, (e) =>
                handleAddPatchPass(uuid, e.latlng)
              )
              mapDrawHooks.AddEventPolyline(map, uuid, () => {
                handleSaveHistoric(uuid, 'polyline')
                handleUpdateShape(uuid, 'polyline')
              })
              break
          }
        })

        selectShape(newUuid, 'polyline')

        return [...prevHistoric, cutShape]
      } else {
        return prevHistoric
      }
    })
  }

  const handleClickVertex = (uuidVertex: string, latlng: number[]) => {
    setActiveTool((pevActiveTool) => {
      if (pevActiveTool === 'cut') {
        handleCutVertexPolyline(uuidVertex, latlng)
      } else {
        handleRemoveVertexPolyline(uuidVertex, latlng)
      }

      return pevActiveTool
    })
  }

  const undoShape = () => {
    if (initialData !== undefined && historic.length <= 1) {
      return
    } else {
      mapDrawHooks.RemoveAllPolylines(map)
      mapHooks.RemoveAllOverlay(map, 'pendence')
      allUuids.map((uuid) => {
        switch (historic[historic.length - 2][uuid].type) {
          case 'polyline':
            mapDrawHooks.RenderPolyline(
              map,
              uuid,
              historic[historic.length - 2][uuid].latlng,
              (e) => {
                setActiveTool((prevActiveTool) => {
                  if (prevActiveTool !== 'cut') {
                    return 'polyline'
                  } else {
                    return 'cut'
                  }
                })
                setUuidEditing(uuid)
                handleDestinationPendence(uuid)
                handleUpdateShape(uuid, 'polyline')
                handleClickVertex(uuid, e.latlng)
              }
            )
            mapDrawHooks.AddRightClickEventPolyline(map, uuid, (e) =>
              handleAddPatchPass(uuid, e.latlng)
            )
            mapDrawHooks.AddEventPolyline(map, uuid, () => {
              handleSaveHistoric(uuid, 'polyline')
              handleUpdateShape(uuid, 'polyline')
            })
            break
        }
      })

      if (allUuids.includes(uuidEditing)) {
        selectShape(uuidEditing, activeTool)
      } else {
        mapDrawHooks.RemoveMapEventClick(map)
        setUuidEditing('')
        setActiveTool('navigation')
      }
      setHistoric((prevHistoric: any) => {
        const removeIndex = historic.length - 1 <= 0 ? 0 : historic.length - 1
        return prevHistoric.filter(
          (_: any, index: number) => index !== removeIndex
        )
      })
    }
  }

  const copyShape = () => {
    if (uuidEditing !== '') {
      const uuid = uuidv4()

      setHistoric((prevHistoric: any) => {
        const copiedShape = prevHistoric[prevHistoric.length - 1][uuidEditing]

        return [
          ...prevHistoric,
          {
            ...prevHistoric[prevHistoric.length - 1],
            [uuid]: {
              patch: [],
              type: 'polyline',
              latlng: copiedShape.latlng.map((latlng: number[]) => {
                return [latlng[0] + -0.00025, latlng[1] + -0.00025]
              })
            }
          }
        ]
      })
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      ReRenderHistoric()
      selectShape(uuid, 'polyline')
      setUuidEditing('')
    }
  }

  const deleteShape = () => {
    if (uuidEditing !== '') {
      setHistoric((prevHistoric: any) => {
        let deletedShape: any = {}
        const prevAllUuids = Object.keys(prevHistoric[prevHistoric.length - 1])

        prevAllUuids
          .filter((uuid) => uuid !== uuidEditing)
          .map((uuid) => {
            deletedShape = {
              ...deletedShape,
              [uuid]: prevHistoric[prevHistoric.length - 1][uuid]
            }
          })

        mapDrawHooks.RemoveAllPolylines(map)
        mapDrawHooks.RemoveMapEventClick(map)
        setActiveTool('navigation')
        Object.keys(deletedShape).map((uuid) => {
          switch (deletedShape[uuid as keyof typeof deletedShape].type) {
            case 'polyline':
              mapDrawHooks.RenderPolyline(
                map,
                uuid,
                deletedShape[uuid].latlng,
                (e) => {
                  setActiveTool((prevActiveTool) => {
                    if (prevActiveTool !== 'cut') {
                      return 'polyline'
                    } else {
                      return 'cut'
                    }
                  })
                  setUuidEditing(uuid)
                  handleDestinationPendence(uuid)
                  handleUpdateShape(uuid, 'polyline')
                  handleClickVertex(uuid, e.latlng)
                }
              )
              mapDrawHooks.AddRightClickEventPolyline(map, uuid, (e) =>
                handleAddPatchPass(uuid, e.latlng)
              )
              mapDrawHooks.AddEventPolyline(map, uuid, () => {
                handleSaveHistoric(uuid, 'polyline')
                handleUpdateShape(uuid, 'polyline')
              })
              break
          }
        })

        return [...prevHistoric, deletedShape]
      })
      setUuidEditing('')
    }
  }

  function handleToolAction(action: string) {
    switch (action) {
      case 'undo':
        undoShape()
        break
      case 'delete':
        deleteShape()
        break
      case 'copy':
        copyShape()
        break
      default:
    }
  }

  const handleCreateShape = (shapeType: string) => {
    const uuid = uuidv4()

    mapDrawHooks.RemoveMapEventClick(map)
    mapDrawHooks.AddMapEventClick(map, (createEvent) => {
      switch (shapeType) {
        case 'navigation':
          mapDrawHooks.RemoveMapEventClick(map)
          break
        case 'cut':
          mapDrawHooks.RemoveMapEventClick(map)
          break
        case 'polyline':
          mapDrawHooks.CreatePolyline(
            map,
            uuid,
            createEvent.latlng,
            (e: any) => {
              setActiveTool((prevActiveTool) => {
                if (prevActiveTool !== 'cut') {
                  return 'polyline'
                } else {
                  return 'cut'
                }
              })
              setUuidEditing(uuid)
              selectShape(uuid, 'polyline')
              handleUpdateShape(uuid, 'polyline')
              handleClickVertex(uuid, e.latlng)
              handleAddPatchPass(uuid, e.latlng)
            }
          )
          mapDrawHooks.AddRightClickEventPolyline(map, uuid, (e) =>
            handleAddPatchPass(uuid, e.latlng)
          )
          handleSaveHistoric(uuid, 'polyline')
          handleUpdateShape(uuid, 'polyline')
          break
        default:
          break
      }
    })
  }

  const getInitialMapShape = () => {
    let initialHistoric = {}
    let uuidList: string[] = []

    mapHooks.removeAllPolylines(map)
    initialData?.polylines &&
      initialData.polylines.map(() => {
        const uuid = uuidv4()
        uuidList = [...uuidList, uuid]
      })

    initialData?.polylines &&
      initialData.polylines.map((polyline, index, polylines) => {
        mapDrawHooks.RenderPolyline(
          map,
          uuidList[index],
          polyline.latlngs,
          (e) => {
            setActiveTool((prevActiveTool) => {
              if (prevActiveTool !== 'cut') {
                return 'polyline'
              } else {
                return 'cut'
              }
            })
            setUuidEditing(uuidList[index])
            handleDestinationPendence(uuidList[index])
            handleUpdateShape(uuidList[index], 'polyline')
            handleClickVertex(uuidList[index], e.latlng)
          }
        )

        mapDrawHooks.AddRightClickEventPolyline(map, uuidList[index], (e) =>
          handleAddPatchPass(uuidList[index], e.latlng)
        )
        mapDrawHooks.AddEventPolyline(map, uuidList[index], () => {
          handleSaveHistoric(uuidList[index], 'polyline')
          handleUpdateShape(uuidList[index], 'polyline')
        })

        initialHistoric = {
          ...initialHistoric,
          [uuidList[index]]: {
            patch: initialData.groupsTwins
              .map((item) => item.sort((a, b) => a.index - b.index))
              .flat()
              .map((item, indexItem) => {
                item.index = indexItem + 1
                return item
              })
              .filter((item) => item.originUuid === polyline.uuid)
              .map((item) => {
                return {
                  ...item,
                  destinationUuid:
                    uuidList[
                      polylines.findIndex(
                        (polylineItem) =>
                          polylineItem.uuid === item.destinationUuid
                      )
                    ],
                  originUuid: uuidList[index],
                  menuChoice: item.menuChoice ?? null // TODO: Mudar aqui oq receber da API
                }
              }),
            type: 'polyline',
            latlng: polyline.latlngs
          }
        }
      })

    map?.fitBoundsPolylines('polyline')

    try {
      const { latitude, longitude } = JSON.parse(
        localStorage.getItem('location') ??
          String({
            latitude: null,
            longitude: null
          })
      )

      if (latitude && longitude && !initialData?.polylines.length) {
        map?.setZoom(15)
        setTimeout(() => {
          map?.setCenter([latitude, longitude])
        }, 100)
      }
    } catch (error) {}

    setHistoric([initialHistoric])
  }

  const changeActiveTool = (tool: string) => {
    setGroupsTwins((prevGroupTwins) => {
      map?.removeRuler()
      switch (tool) {
        case 'navigation':
          setActiveTool('navigation')
          handleCreateShape('navigation')
          mapDrawHooks.ResetShapeColor(
            map,
            disableColor,
            prevGroupTwins.flat().map((item) => item.destinationUuid)
          )
          break
        case 'polyline':
          setActiveTool((prevActiveTool) => {
            if (prevActiveTool !== 'cut') {
              return 'polyline'
            } else {
              return 'cut'
            }
          })
          handleCreateShape('polyline')
          mapDrawHooks.ResetShapeColor(
            map,
            disableColor,
            prevGroupTwins.flat().map((item) => item.destinationUuid)
          )
          break
        case 'cut':
          setActiveTool('cut')
          handleCreateShape('cut')
          mapDrawHooks.ResetShapeColor(
            map,
            disableColor,
            prevGroupTwins.flat().map((item) => item.destinationUuid)
          )
          break
        case 'ruler':
          setActiveTool('ruler')
          map?.createRuler()
          mapDrawHooks.ResetShapeColor(
            map,
            disableColor,
            prevGroupTwins.flat().map((item) => item.destinationUuid)
          )
          break
        default:
      }
      return prevGroupTwins
    })
  }

  useEffect(() => {
    mapHooks.removeAllPolylines(map)
    if (initialData && map) {
      getInitialMapShape()
    }
  }, [initialData, map])

  const getMapShapes = () => {
    const lastUuids =
      historic.length >= 1 ? Object.keys(historic[historic.length - 1]) : []
    const lastHistoric = historic[historic.length - 1]

    const mapData = {
      polylines: lastUuids
        .filter((uuid) => lastHistoric[uuid].type === 'polyline')
        .map((uuid) => {
          return {
            uuid: uuid,
            latlngs: lastHistoric[uuid].latlng
          }
        }),
      groupsTwins: groupsTwins.map((group, index, grops) => {
        group.map((itemGroup: ShapeTwins) => {
          const exist = grops[index].findIndex(
            (item: ShapeTwins) => item.originUuid === itemGroup.destinationUuid
          )
          if (exist === -1) {
            group = [
              ...group,
              {
                originUuid: itemGroup.destinationUuid,
                destinationUuid: null,
                sequences: null
              }
            ]
          }
        })
        return group
      }),
      formasAlteradas: historic.length > 1
    }

    getMapData(mapData)
  }

  useEffect(() => {
    getMapShapes()
  }, [historic, groupsTwins])

  useEffect(() => {
    const lastUuids =
      historic.length >= 1 ? Object.keys(historic[historic.length - 1]) : []
    const lastHistoric = historic[historic.length - 1]

    let sum = 0
    if (lastUuids.find((uuid) => lastHistoric[uuid].latlng.length >= 2)) {
      lastUuids?.map((uuid: string) => {
        lastHistoric[uuid].latlng.map((latlng: number[], index: number) => {
          if (index >= 1) {
            sum =
              sum +
              mapDrawHooks.coordinatesToKm(
                lastHistoric[uuid].latlng[index - 1],
                latlng
              )
            if (
              mapDrawHooks.coordinatesToKm(
                lastHistoric[uuid].latlng[index - 1],
                latlng
              ) >= 5
            ) {
              undoShape()
              enqueueSnackbar(
                'Não pode haver mais do que 5 kilometros entre um ponto e outro',
                {
                  variant: 'warning'
                }
              )
            }
          }
        })
      })
    } else {
      sum = 0
    }
    setKmDrawn(sum)
  }, [historic])

  const handleSearchCoordinates = async () => {
    if (
      searchAddress.trim() !== '' &&
      map &&
      !lockSearchAddress &&
      !loadingSearchAddress
    ) {
      setLoadingSearchAddress(true)
      try {
        const response = await getCoordinateByAddress(searchAddress)
        if (response.data) {
          const { latitude, longitude } = response.data
          map.setZoom(15)
          setTimeout(() => {
            map.setCenter([latitude, longitude])
          }, 100)
          localStorage.setItem(
            'location',
            JSON.stringify({ latitude, longitude })
          )
        } else {
          enqueueSnackbar(response?.message, {
            variant: 'error'
          })
        }
        setLockSearchAddress(true)
        setLoadingSearchAddress(false)
      } catch (error) {
        setLockSearchAddress(true)
        setLoadingSearchAddress(false)
        enqueueSnackbar('Nenhum endereço foi encontrado!', {
          variant: 'error'
        })
      }
    }
  }

  useEffect(() => {
    if (map && initialData && countEventKey === 0) {
      const keyDownHandler = (event: any) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
          event.preventDefault()
          document.getElementById('undo')?.click()
        }
      }

      document.addEventListener('keydown', keyDownHandler)

      setCountEventKey(1)
    }
  }, [historic])

  useEffect(() => {
    if (historic.length >= 1) {
      let groups: any[] = []

      Object.keys(historic[historic.length - 1])
        .map((uuid: string) => {
          return historic[historic.length - 1][uuid].patch.map((patch: any) => {
            return {
              ...patch,
              menuChoice: patch.menuChoice,
              originUuid: uuid
            }
          })
        })
        .flat()
        .sort((a, b) => a.index - b.index)
        .map((patch: any) => {
          if (groups.length > 0) {
            groups.map((patchGroup: any, index: number) => {
              const exists = groups
                .flat()
                .sort((a, b) => a.index - b.index)
                .findIndex(
                  (item) =>
                    item.destinationUuid === patch.originUuid ||
                    item.originUuid === patch.destinationUuid ||
                    item.destinationUuid === patch.destinationUuid ||
                    item.originUuid === patch.originUuid
                )

              const indexOfGroup = groups.findIndex((group: ShapeTwins[]) =>
                group
                  .sort((a, b) => a.index - b.index)
                  .map((item) => item.index)
                  .includes(patch.index)
              )

              if (
                (patchGroup
                  .map((item: any) => item.destinationUuid)
                  .includes(patch.originUuid) ||
                  patchGroup
                    .map((item: any) => item.originUuid)
                    .includes(patch.destinationUuid) ||
                  patchGroup
                    .map((item: any) => item.originUuid)
                    .includes(patch.originUuid) ||
                  patchGroup
                    .map((item: any) => item.destinationUuid)
                    .includes(patch.destinationUuid)) &&
                exists !== -1
              ) {
                if (indexOfGroup !== -1) {
                  groups[indexOfGroup] = [
                    ...groups[indexOfGroup],
                    ...groups[index]
                  ]
                  groups = groups.filter((_, i) => i !== index)
                } else {
                  groups[index] = [...groups[index], patch]
                }
              } else if (exists === -1) {
                groups = [...groups, [patch]]
              }
            })
          } else {
            groups = [...groups, [patch]]
          }
        })
      setGroupsTwins(groups)
    }
  }, [historic])

  const ReRenderHistoric = () => {
    setHistoric((prevHistoric: any) => {
      mapDrawHooks.RemoveAllPolylines(map)
      Object.keys(prevHistoric[prevHistoric.length - 1]).map(
        (uuidMap: string) => {
          mapDrawHooks.RenderPolyline(
            map,
            uuidMap,
            prevHistoric[prevHistoric.length - 1][uuidMap].latlng,
            (e) => {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              handleClickVertex(uuidMap, e.latlng)
              setActiveTool((prevActiveTool) => {
                if (prevActiveTool !== 'cut') {
                  return 'polyline'
                } else {
                  return 'cut'
                }
              })
              setUuidEditing(uuidMap)
              handleDestinationPendence(uuidMap)
              handleUpdateShape(uuidMap, 'polyline')
              setHistoric((prevHistoricUpdate: any) => {
                return [...prevHistoricUpdate]
              })
              mapHooks.RemoveAllOverlay(map, 'pendence')
            }
          )
          mapDrawHooks.AddRightClickEventPolyline(map, uuidMap, (e) =>
            handleAddPatchPass(uuidMap, e.latlng)
          )
          mapDrawHooks.AddEventPolyline(map, uuidMap, () => {
            handleSaveHistoric(uuidMap, 'polyline')
            handleUpdateShape(uuidMap, 'polyline')
          })
        }
      )
      return [...prevHistoric]
    })
  }

  const createMenu = ({
    latlong,
    patch
  }: {
    patch: ShapeTwins
    latlong: number[]
  }) => {
    // Criar o elemento do menu
    const menuElement = document.createElement('div')
    menuElement.style.cssText = `
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      color: black;
      height: 120px;
      width: 200px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translateX(-100px) translateY(-70px);
      white-space: pre-line;
      line-height: 1.5;
      background: rgba(255, 255, 255, 1.3);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
      border-radius: 10px;
      position: fixed;
      z-index: 9999;
    `

    // Título do menu
    const titleElement = document.createElement('div')
    titleElement.innerText = 'Seleciona uma opção para seguir'
    titleElement.style.cssText = `
      font-size: 12px;
      margin-bottom: 10px;
    `
    menuElement.appendChild(titleElement)

    // Opção Guias Paralelas
    const parallelGuidesOption = document.createElement('button')
    parallelGuidesOption.innerText = 'Guias Paralelas'
    parallelGuidesOption.setAttribute('id', `parallelId`)
    parallelGuidesOption.style.cssText = `
      font-size: 12px;
      margin: 5px;
      padding: 5px 10px;
      cursor: pointer;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      border-radius: 5px;
    `
    parallelGuidesOption.onclick = () => {
      // Função a ser chamada quando a opção Guias Paralelas for selecionada
      handleParallelGuides(patch.originUuid)
    }
    menuElement.appendChild(parallelGuidesOption)

    // Opção Sequência de Transição
    const transitionSequenceOption = document.createElement('button')
    transitionSequenceOption.innerText = 'Sequência de Transição'
    transitionSequenceOption.setAttribute('id', `twinId`)
    transitionSequenceOption.style.cssText = `
      font-size: 12px;
      margin: 5px;
      padding: 5px 10px;
      cursor: pointer;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      border-radius: 5px;
    `
    transitionSequenceOption.onclick = () => {
      // Função a ser chamada quando a opção Sequência de Transição for selecionada
      handleTransitionSequence(patch.originUuid)
    }
    menuElement.appendChild(transitionSequenceOption)

    mapHooks.RenderOverlay(map, menuElement, latlong, 'menu-choices')
    // MAPA LEAFLET
    document.getElementById('twinId')?.addEventListener('click', () => {
      handleTransitionSequence(patch.originUuid)
    })
    document.getElementById('parallelId')?.addEventListener('click', () => {
      handleParallelGuides(patch.originUuid)
    })
  }

  // Função para Guias Paralelas
  const handleParallelGuides = (originUuid: number | string) => {
    let changeState = false
    setHistoric((prevHistoric: any) => {
      const currentHistoric = prevHistoric[prevHistoric.length - 1][originUuid]
      const existDestinyInPatchHistory = Object.values(
        prevHistoric[prevHistoric.length - 1]
      ).find((el: any) =>
        el?.patch.find((pat: any) => pat.destinationUuid === originUuid)
      ) as any
      const existOriginInPatchHistory = Object.values(
        prevHistoric[prevHistoric.length - 1]
      ).find((el: any) =>
        el?.patch.find((pat: any) => pat.originUuid === originUuid)
      ) as any
      if (
        currentHistoric.patch.length === 1 &&
        !existDestinyInPatchHistory &&
        !existOriginInPatchHistory
      ) {
        currentHistoric.patch[currentHistoric.patch.length - 1].menuChoice =
          'Parallel'
        currentHistoric.patch[
          currentHistoric.patch.length - 1
        ].destinationUuid = null
        changeState = true
      } else {
        mapHooks.RemoveAllOverlay(map, 'menu-choices')
        currentHistoric.patch = currentHistoric.patch.filter(
          (el: any) => el.menuChoice !== null
        )
        enqueueSnackbar(
          'Não é possivel usar Guias paralelas em formas que já tem sequencia de transição',
          {
            variant: 'warning'
          }
        )
      }

      return prevHistoric
    })
    if (changeState) {
      setGroupsTwins((prev) => {
        return prev.map((el) => {
          return el.map((item: any) => {
            if (item.originUuid === originUuid) {
              item.menuChoice = 'Parallel'
              item.destinationUuid = null
            }
            return item
          })
        })
      })
    }
    // Adicione aqui a lógica específica para Guias Paralelas
  }

  // Função para Sequência de Transição
  const handleTransitionSequence = (originUuid: number | string) => {
    setHistoric((prevHistoric: any) => {
      const currentHistoric = prevHistoric[prevHistoric.length - 1][originUuid]
      currentHistoric.patch[currentHistoric.patch.length - 1].menuChoice =
        'Twin'
      currentHistoric.patch[currentHistoric.patch.length - 1].destinationUuid =
        null

      return prevHistoric
    })
    setGroupsTwins((prev) => {
      return prev.map((el) => {
        return el.map((item: any) => {
          if (item.originUuid === originUuid) {
            item.menuChoice = 'Twin'
            item.destinationUuid = null
          }
          return item
        })
      })
    })
  }

  const determineDirection = (coords: [number, number][]): string => {
    if (coords.length < 2) return 'Insufficient data'

    const initialLat = coords[0][0]
    const finalLat = coords[coords.length - 1][0]

    if (finalLat > initialLat) {
      return 'Norte'
    } else if (finalLat < initialLat) {
      return 'Sul'
    } else {
      return 'Sem movimento latitudinal'
    }
  }

  function calculateMiddle(array: string | any[]) {
    const totalElements = array.length
    const middle = (totalElements - 1) / 2
    return Math.round(middle)
  }

  const arraysEqual = (
    a: [number, number][],
    b: [number, number][]
  ): boolean => {
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) {
      if (a[i][0] !== b[i][0] || a[i][1] !== b[i][1]) return false
    }
    return true
  }

  function adjustPolyline(latLng: number[], latLngArray: any[]) {
    const tolerance = 1e-10 // Tolerância para comparação de ponto flutuante

    const nearestIndex = latLngArray.findIndex((point) => {
      const isMatch =
        Math.abs(point[0] - latLng[0]) < tolerance &&
        Math.abs(point[1] - latLng[1]) < tolerance
      return isMatch
    })

    const isNearStart = nearestIndex < latLngArray.length / 2
    const adjustedArray = !isNearStart ? latLngArray : latLngArray.reverse()

    const isEqual = arraysEqual(latLngArray, adjustedArray)
    return {
      adjustedArray,
      adjustedIndex: calculateMiddle(adjustedArray),
      arrayReverse: isEqual // Trocado depois
    }
  }

  const handleOrderPolylinesParallel = (
    clickedPoint: number[],
    currentPolyline: number[][] | [any, any][]
  ) => {
    const ajustPolylines = adjustPolyline(clickedPoint, currentPolyline)
    return {
      index: ajustPolylines.adjustedIndex,
      sortedPolyline: ajustPolylines.adjustedArray,
      arrayReordered: !ajustPolylines.arrayReverse
    }
  }

  useEffect(() => {
    const currentHistoric = historic[historic.length - 1]
    const lastHistoric = historic[historic.length - 2]

    if (historic.length >= 1) {
      mapDrawHooks.removeAllPatch(map)
      mapHooks.RemoveAllOverlay(map, 'distance')

      groupsTwins.map((group: ShapeTwins[]) => {
        group.map((patch, index: number, groups: ShapeTwins[]) => {
          const existDestination = groups.findIndex(
            (item) => item.destinationUuid === patch.destinationUuid
          )

          if (existDestination !== -1) {
            index = existDestination
          }

          if (patch.menuChoice === 'Twin' && patch.destinationUuid) {
            mapDrawHooks.AlterPolylineColor(
              map,
              String(patch.destinationUuid),
              colorByIndex(index)
            )
          }

          if (patch.menuChoice === 'Parallel' && patch.destinationUuid) {
            const directionOrigin = determineDirection(
              currentHistoric[patch.originUuid].latlng
            )
            const directionDestin = determineDirection(
              currentHistoric[patch.destinationUuid].latlng
            )
            const isSameDirect = directionDestin === directionOrigin
            if (isSameDirect) {
              setHistoric((prevHistoric: any) => {
                const sorted = [
                  ...currentHistoric[patch.destinationUuid!].latlng
                ].reverse()

                prevHistoric[prevHistoric.length - 1][
                  patch.destinationUuid!
                ].latlng = sorted
                return prevHistoric
              })
              setGroupsTwins((prev) => {
                return prev.map((el) => {
                  return el.map((item: any) => {
                    if (item.originUuid === patch.originUuid) {
                      item.menuChoice = 'Parallel'
                    }
                    return item
                  })
                })
              })
            } else {
              // Exemplo de uso
              const guideId = `${patch.originUuid}`
              const { dark: darkColor, light: lightColor } =
                getGuideColorPair(guideId)

              patch.destinationUuid &&
                mapDrawHooks.AlterPolylineColor(
                  map,
                  String(patch.destinationUuid),
                  darkColor
                )
              patch.originUuid &&
                mapDrawHooks.AlterPolylineColor(
                  map,
                  String(patch.originUuid),
                  lightColor
                )

              mapDrawHooks.renderPatch(
                map,
                darkColor,
                currentHistoric[patch.destinationUuid!].latlng,
                index + 1,
                (e) => {
                  setActiveTool((prevActiveTool) => {
                    if (prevActiveTool !== 'cut') {
                      return 'polyline'
                    } else {
                      return 'cut'
                    }
                  })
                  setUuidEditing(String(patch.originUuid))
                  handleUpdateShape(String(patch.originUuid), 'polyline')
                  handleClickVertex(String(patch.originUuid), e.latlng)
                },
                PolylineType.Arrow
              )
            }
          }
        })
      })

      mapHooks.RemoveAllOverlay(map, 'pendence')
      mapHooks.RemoveAllOverlay(map, 'menu-choices')
      mapHooks.RemoveAllOverlay(map, 'marker')

      groupsTwins.map((group: ShapeTwins[]) => {
        group.map((patch, index: number, groups: ShapeTwins[]) => {
          if (patch.sequences) {
            if (!patch.menuChoice) {
              createMenu({
                patch,
                latlong: patch.latLongClick
              })
            }

            if (patch.menuChoice === 'Twin') {
              const distance =
                mapDrawHooks.coordinatesToKm(
                  currentHistoric[patch.originUuid].latlng[patch.sequences[0]],
                  currentHistoric[patch.originUuid].latlng[patch.sequences[1]]
                ) * 1000

              if (distance <= upperLimit && distance >= lowerLimit) {
                if (patch.destinationUuid === null) {
                  const elementPendence = document.createElement('div')
                  elementPendence.innerHTML =
                    'Selecione uma forma para dar sequência à transição!'
                  elementPendence.style.cssText =
                    'font-size: 12px;font-weight: 600; letter-spacing: 1px;color: black;height: 60px; width: 160px;text-align: center;display: flex;justify-content: center;align-items: center;transform: translateX(-80px) translateY(-70px); white-space: pre-line; line-height: 1; background: rgb(255, 255, 255, 1.3); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px; border-radius: 10px;'
                  elementPendence.style.position = 'absolute'
                  mapHooks.RenderOverlay(
                    map,
                    elementPendence,
                    currentHistoric[patch.originUuid].latlng[
                      patch.sequences[1]
                    ],
                    'pendence'
                  )
                }

                if (index === 0 && patch.destinationUuid !== null) {
                  const elementFlag = document.createElement('div')
                  elementFlag.innerHTML = `<img style="height: 24px; width: 24px; transform: translateX(-12px) translateY(-30px);" src="https://cdn-icons-png.flaticon.com/512/6153/6153497.png"/>`
                  elementFlag.style.position = 'absolute'
                  mapHooks.RenderOverlay(
                    map,
                    elementFlag,
                    currentHistoric[patch.originUuid].latlng[
                      patch.sequences[1]
                    ],
                    'marker'
                  )
                }

                const existDestination = groups.findIndex(
                  (item) => item.destinationUuid === patch.destinationUuid
                )

                if (existDestination !== -1) {
                  index = existDestination
                }

                mapDrawHooks.renderPatch(
                  map,
                  colorByIndex(index),
                  patch.sequences.map(
                    (item: number) =>
                      currentHistoric[patch.originUuid].latlng[item]
                  ),
                  index + 1,
                  (e) => {
                    setActiveTool((prevActiveTool) => {
                      if (prevActiveTool !== 'cut') {
                        return 'polyline'
                      } else {
                        return 'cut'
                      }
                    })
                    setUuidEditing(String(patch.originUuid))
                    handleUpdateShape(String(patch.originUuid), 'polyline')
                    handleClickVertex(String(patch.originUuid), e.latlng)
                  }
                )
                const element = document.createElement('div')
                element.innerHTML = `${distance}m`
                element.style.cssText =
                  'font-size: 12px; font-weight: 600; letter-spacing: 1px; color: black;height: 20px; width: 40px; text-align: center; display: flex; justify-content: center; align-items: center; transform: translateX(-20px) translateY(-25px); white-space: pre-line; line-height: 1; background: rgb(255, 255, 255, 1.3); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px; border-radius: 5px;'
                element.style.position = 'absolute'
                mapHooks.RenderOverlay(
                  map,
                  element,
                  currentHistoric[patch.originUuid].latlng[patch.sequences[0]],
                  'distance'
                )
              } else {
                const currentFirstPatchLatlng =
                  currentHistoric[patch.originUuid].latlng[patch.sequences[0]]
                const currentSecondPatchLatlng =
                  currentHistoric[patch.originUuid].latlng[patch.sequences[1]]
                const lastFirstPatchLatlng =
                  lastHistoric[patch.originUuid].latlng[patch.sequences[0]]
                const lastSecondPatchLatlng =
                  lastHistoric[patch.originUuid].latlng[patch.sequences[1]]
                const path = map?.getPolylinePath(
                  'polyline',
                  (object: { uuid: string }) => object.uuid === patch.originUuid
                )!

                if (distance > upperLimit || distance < lowerLimit) {
                  let reShapeDistance = 0

                  if (distance > upperLimit) {
                    reShapeDistance = upperLimit
                    enqueueSnackbar(
                      'Pontos de transição devem ser menor que 30 metros',
                      {
                        variant: 'warning'
                      }
                    )
                  } else if (distance < lowerLimit) {
                    reShapeDistance = lowerLimit
                    enqueueSnackbar(
                      'Pontos de transição devem ser maior que 10 metros',
                      {
                        variant: 'warning'
                      }
                    )
                  }
                  if (
                    currentFirstPatchLatlng[0] !== lastFirstPatchLatlng[0] ||
                    currentFirstPatchLatlng[1] !== lastFirstPatchLatlng[1]
                  ) {
                    const newLatlng = mapDrawHooks.calculateCoordinates(
                      currentSecondPatchLatlng,
                      currentFirstPatchLatlng,
                      reShapeDistance
                    )
                    path.splice(patch.sequences[0], 1)
                    path.splice(patch.sequences[0], 0, newLatlng)
                  }
                  if (
                    currentSecondPatchLatlng[0] !== lastSecondPatchLatlng[0] ||
                    currentSecondPatchLatlng[1] !== lastSecondPatchLatlng[1]
                  ) {
                    const newLatlng = mapDrawHooks.calculateCoordinates(
                      currentFirstPatchLatlng,
                      currentSecondPatchLatlng,
                      reShapeDistance
                    )
                    path.splice(patch.sequences[1], 1)
                    path.splice(patch.sequences[1], 0, newLatlng)
                  }
                }
                if (
                  path.length > lastHistoric[patch.originUuid].latlng.length
                ) {
                  undoShape()
                } else {
                  setHistoric((prevHistoric: any) => {
                    prevHistoric[prevHistoric.length - 1][
                      patch.originUuid
                    ].latlng = path
                    return prevHistoric
                  })
                  ReRenderHistoric()
                  return
                }
              }
            }

            let orderingParams = null

            if (patch.menuChoice === 'Parallel') {
              if (patch.latLongClick && patch.destinationUuid === null) {
                const orderOriginUuid = handleOrderPolylinesParallel(
                  patch.latLongClick,
                  currentHistoric[patch.originUuid].latlng
                )
                const isTwinPatch = currentHistoric[
                  patch.originUuid
                ].patch.find(
                  (el: { menuChoice: string }) => el.menuChoice === 'Twin'
                )

                orderingParams = orderOriginUuid
                if (orderOriginUuid.arrayReordered && !isTwinPatch) {
                  setHistoric((prevHistoric: any) => {
                    prevHistoric[prevHistoric.length - 1][
                      patch.originUuid
                    ].latlng = orderOriginUuid.sortedPolyline
                    prevHistoric[prevHistoric.length - 1][
                      patch.originUuid
                    ].patch[0].sequences = [
                      orderOriginUuid.index - 1,
                      orderOriginUuid.index
                    ]
                    return prevHistoric
                  })
                  setGroupsTwins((prev) => {
                    return prev.map((el) => {
                      return el.map((item: any) => {
                        if (item.originUuid === patch.originUuid) {
                          item.menuChoice = 'Parallel'
                          item.destinationUuid = null
                          item.sequences = [
                            orderOriginUuid.index - 1,
                            orderOriginUuid.index
                          ]
                        }
                        return item
                      })
                    })
                  })
                  return
                }
              }

              const guideId = `${patch.originUuid}`
              const { light: lightColor } = getGuideColorPair(guideId)
              if (patch.destinationUuid === null) {
                const elementPendence = document.createElement('div')
                elementPendence.innerHTML = 'Selecione a forma da Proxima Guia'
                elementPendence.style.cssText =
                  'font-size: 12px;font-weight: 600; letter-spacing: 1px;color: black;height: 60px; width: 160px;text-align: center;display: flex;justify-content: center;align-items: center;transform: translateX(-80px) translateY(-70px); white-space: pre-line; line-height: 1; background: rgb(255, 255, 255, 0.8); box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px; border-radius: 10px;'
                elementPendence.style.position = 'absolute'

                // TODO: ARRUMRA
                mapHooks.RenderOverlay(
                  map,
                  elementPendence,
                  currentHistoric[patch.originUuid].latlng[
                    orderingParams?.index ?? 0
                  ],
                  'pendence'
                )
                mapDrawHooks.renderPatch(
                  map,
                  lightColor,
                  currentHistoric[patch.originUuid].latlng,
                  index + 1,
                  (e) => {
                    console.log('CLICOU AQUI')
                    setActiveTool((prevActiveTool) => {
                      if (prevActiveTool !== 'cut') {
                        return 'polyline'
                      } else {
                        return 'cut'
                      }
                    })
                    setUuidEditing(String(patch.originUuid))
                    handleUpdateShape(String(patch.originUuid), 'polyline')
                    handleClickVertex(String(patch.originUuid), e.latlng)
                  },
                  PolylineType.Arrow
                )
              } else {
                if (index === 0 && patch.destinationUuid !== null) {
                  const elementFlag = document.createElement('div')
                  elementFlag.innerHTML = `<img style="height: 24px; width: 24px; transform: translateX(-12px) translateY(-30px);" src="https://cdn-icons-png.flaticon.com/512/15709/15709604.png"/>`
                  elementFlag.style.position = 'absolute'
                  mapHooks.RenderOverlay(
                    map,
                    elementFlag,
                    currentHistoric[patch.originUuid].latlng[
                      currentHistoric[patch.originUuid].latlng.length - 1
                    ],
                    'marker'
                  )
                  mapDrawHooks.AlterPolylineColor(
                    map,
                    String(patch.originUuid),
                    lightColor
                  )
                  mapDrawHooks.renderPatch(
                    map,
                    lightColor,
                    currentHistoric[patch.originUuid].latlng,
                    index + 1,
                    (e) => {
                      console.log('CLICOU AQUI')
                      setActiveTool((prevActiveTool) => {
                        if (prevActiveTool !== 'cut') {
                          return 'polyline'
                        } else {
                          return 'cut'
                        }
                      })
                      setUuidEditing(String(patch.originUuid))
                      handleUpdateShape(String(patch.originUuid), 'polyline')
                      handleClickVertex(String(patch.originUuid), e.latlng)
                    },
                    PolylineType.Arrow
                  )
                }
              }
            }
          }
        })
      })
    }
  }, [groupsTwins])

  return (
    <Wrapper wrapperHeight={wrapperHeight}>
      <MapContainer>
        <MapSearch>
          <SearchBar
            id="search-coordinates"
            type="text"
            placeholder="Buscar endereço..."
            value={searchAddress}
            onChange={(e: any) => {
              setLockSearchAddress(false)
              setSearchAddress(e.target.value)
            }}
            onKeyUp={(e: any) => {
              const { key, keyCode } = e
              if (
                (key === 'Enter' || keyCode === 13) &&
                !lockSearchAddress &&
                !loadingSearchAddress
              ) {
                handleSearchCoordinates()
              }
            }}
          />
          <Button
            id="button-coordinates"
            disabled={searchAddress === '' || lockSearchAddress}
            onClick={() => handleSearchCoordinates()}
          >
            {loadingSearchAddress ? <SpinnerLoading size="md" /> : <Search />}
          </Button>
        </MapSearch>
        {groupsTwins.length > 0 && (
          <Groups>
            <GroupComponent
              groups={groupsTwins}
              colorByIndex={colorByIndex}
              colorByParallel={getGuideColorPair}
            />
          </Groups>
        )}

        <MapArea id="map-draw" />
        <KmDrawn
          alert={kmDrawn > 25 ? 'danger' : kmDrawn > 15 ? 'warning' : 'primary'}
        >
          {(kmDrawn * 1000).toFixed(0) + 'm'}
        </KmDrawn>
      </MapContainer>
      <ToolsList>
        <Button
          id="naviagtion"
          color={activeTool === 'navigation' ? 'primary' : 'gray'}
          onClick={() => changeActiveTool('navigation')}
        >
          <NearMe />
        </Button>
        <Button
          id="polyline"
          color={activeTool === 'polyline' ? 'primary' : 'gray'}
          onClick={() => changeActiveTool('polyline')}
        >
          <Polyline />
        </Button>
        <Button
          id="undo"
          color={activeTool === 'undo' ? 'primary' : 'gray'}
          onClick={() => handleToolAction('undo')}
        >
          <Undo />
        </Button>
        <Button
          id="delete"
          color={activeTool === 'undo' ? 'primary' : 'gray'}
          onClick={() => handleToolAction('delete')}
        >
          <Delete />
        </Button>
        <Button
          id="cut"
          color={activeTool === 'cut' ? 'primary' : 'gray'}
          onClick={() => changeActiveTool('cut')}
        >
          <ContentCut />
        </Button>
        <Button
          id="copy"
          color={activeTool === 'undo' ? 'primary' : 'gray'}
          onClick={() => handleToolAction('copy')}
        >
          <ContentCopy />
        </Button>
        <Button
          id="ruler"
          color={activeTool === 'ruler' ? 'primary' : 'gray'}
          onClick={() => changeActiveTool('ruler')}
        >
          <Straighten />
        </Button>
      </ToolsList>
    </Wrapper>
  )
}

export default MapDraw
