import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { Contract } from '../../types/Contract'

export interface AllContractsType {
  codigo: 0
  razaoSocial: 'Todos os contratos'
  chaveGoogle: ''
  raioVarricaoVelocidade: {
    velocidadeInicial: number
    velocidadeFinal: number
    raio: number
  }[]
  identificadorAutenticador: ''
}

export interface ContractState {
  currentContract: Contract | AllContractsType | null
  allContracts: Contract[] | null
}

const initialState: ContractState = {
  currentContract: null,
  allContracts: null
}

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setCurrentContract: (
      state,
      action: PayloadAction<Contract | AllContractsType>
    ) => {
      localStorage.setItem(
        '@lutocar-current-contract',
        JSON.stringify(action.payload)
      )

      return {
        ...state,
        currentContract: action.payload
      }
    },
    removeCurrentContract: (state) => {
      localStorage.setItem(
        '@lutocar-current-contract',
        JSON.stringify({
          codigo: 0,
          razaoSocial: 'Todos os contratos',
          chaveGoogle: '',
          //Provisório
          raioVarricaoVelocidade: [],
          identificadorAutenticador: ''
        })
      )

      return {
        ...state,
        currentContract: {
          codigo: 0,
          razaoSocial: 'Todos os contratos',
          chaveGoogle: '',
          //Provisório
          raioVarricaoVelocidade: [],
          identificadorAutenticador: ''
        }
      }
    },
    setAllContracts: (state, action: PayloadAction<Contract[]>) => {
      return {
        ...state,
        allContracts: action.payload
      }
    }
  }
})

export const { setCurrentContract, removeCurrentContract, setAllContracts } =
  contractSlice.actions

export const selectCurrentContract = (state: RootState) => {
  return state.contract.currentContract
}
export const selectAllContract = (state: RootState) =>
  state.contract.allContracts

export default contractSlice.reducer
