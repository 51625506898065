import { colors } from '../../../assets/variables'
import styled, { css } from 'styled-components'
import { Wrapper as Grid } from '../../../components/Grid/styles'

export const Window = styled.div`
  position: absolute;
  height: 80vh;
  background-color: white;
`

export const MapContainer = styled.div`
  position: relative;
  transition: 0.5s;
`

export const GridContainer = styled.div`
  position: relative;
  transition: 0.5s;
  ${Grid} {
    height: 100%;
  }
  border-top: 1px solid ${colors.borderColor};
  .MuiTableContainer-root {
    box-shadow: none !important;
  }
`

export const FullscreenModal = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 50px;
  height: calc(100vh - 50px);
  width: 100vw;
  background-color: #fff;
`

export const ResizeGrid = styled.div`
  position: absolute;
  height: 25px;
  width: 150px;
  left: calc(50% - 75px);
  top: -25px;
  z-index: 99;
  background-color: white;
  border: 1px solid ${colors.borderColor};
  border-bottom: transparent;
  border-radius: 0.3rem 0.3rem 0 0;
  overflow: hidden;
  display: flex;
`

export const ResizeButton = styled.button`
  height: 25px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    color: ${colors.grayLight};
    height: 20px;
    width: 20px;
  }
  &:nth-child(1) {
    border-right: 1px solid ${colors.borderColor};
  }
  &:hover {
    background-color: ${colors.grayPastel};
  }
  &:disabled {
    cursor: not-allowed;
    svg {
      color: ${colors.gray100};
    }
    &:hover {
      background-color: transparent;
    }
  }
`

export const FilterContainer = styled.div`
  background-color: white;
  border-top: 1px solid ${colors.borderColor};
  padding: 15px;
`

const bigger = css`
  ${MapContainer} {
    height: 20%;
  }
  ${GridContainer} {
    height: 80%;
  }
`

const normal = css`
  ${MapContainer} {
    height: calc(100% - 90px);
  }
  ${GridContainer} {
    height: 90px;
  }
`

const small = css`
  ${MapContainer} {
    height: 100%;
  }
  ${GridContainer} {
    height: 0;
  }
`

const report = css`
  display: none;
  ${MapContainer} {
    height: 0%;
  }
  ${GridContainer} {
    height: 0%;
  }
`

const area = [small, normal, bigger]

export const Wrapper = styled.div<{ resizeArea: number; renderMap: boolean }>`
  height: calc(100vh - 50px);
  overflow: hidden;
  .leaflet-div-icon {
    margin-top: 0 !important;
    margin-left: 0 !important;
    background-color: transparent !important;
    border: none !important;
    height: 0px !important;
    width: 0px !important;
  }
  ${(props) => (props.renderMap ? area[props.resizeArea - 1] : report)}
`

const showInfo = css`
  overflow: hidden;
  width: 34px;
  height: 34px;
  padding: 0;
  border-radius: 50%;
`

export const ViewDetails = styled.div<{ showInfo: boolean }>`
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  background-color: white;
  opacity: 0.9;
  border-radius: 0.3rem;
  padding: 15px;
  padding-top: 5px;
  width: 500px;
  max-height: calc(100vh - 185px);
  overflow-y: auto;
  z-index: 99;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1) !important;
  ${(props) => (!props.showInfo ? showInfo : null)}
  max-width: 95%;
`

const showFilter = css`
  max-height: 34px;
  padding: 0;
`

export const ViewFilterDetails = styled.div<{ showFilter: boolean }>`
  overflow: hidden;
  background-color: white;
  opacity: 1;
  border-radius: 0.3rem;
  width: 100%;
  max-height: calc(209px);
  transition: 0.3s;
  z-index: 99;
  ${(props) => (!props.showFilter ? showFilter : null)}
`

export const ListFilter = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 240px;
  overflow-y: auto;
  padding-left: 15px;
`

export const LegendColor = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 64px;
  max-width: 120px;
  min-width: 64px;
  height: 20px;
  color: ${(props) => props.color};
  font-weight: bold;
  margin-right: 4px;
  &::after {
    content: '';
    display: block;
    margin-left: 5px;
    width: 20px;
    height: 12px;
    background-color: ${(props) => props.color};
  }
`

export const LegendColorProgress = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.color};
  font-weight: bold;
  margin-right: 4px;
  gap: 6px;
  &::before {
    content: '';
    margin-left: 6px;
    width: 18px;
    height: 12px;
    background-color: ${(props) => props.color};
  }
`
