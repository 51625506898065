import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { colors } from '../../../assets/variables'

const borderColor = colors.gray
const borderWidth = 0.5

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    backgroundColor: '#FFFF',
    alignItems: 'center',
    borderTopWidth: 0,
    borderBottomWidth: borderWidth,
    height: 15,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    fontSize: 4,
    color: colors.gray900
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderTop: borderWidth,
    borderRight: borderWidth,
    borderLeft: borderWidth,
    borderColor: borderColor
  },
  textContainer: {
    borderRightColor: borderColor,
    color: colors.grayDark,
    fontWeight: 900,
    fontSize: 5,
    marginBottom: 1
  }
})

export interface ColumnsGridI {
  name: string
  width: string
  textAlign: 'left' | 'center' | 'right' | 'justify' | undefined
}

interface ColumnGridReportI {
  data: ColumnsGridI[]
}

export const ColumnsGridReport = ({ data }: ColumnGridReportI) => {
  return (
    <View style={styles.tableContainer} fixed>
      <View
        style={{
          ...styles.container
        }}
      >
        {data.map((item, index) => {
          return (
            <Text
              key={item.name}
              style={{
                ...styles.textContainer,
                width: item.width,
                textAlign: item.textAlign,
                paddingLeft: index == 0 ? 3 : 2
              }}
            >
              {item.name}
            </Text>
          )
        })}
      </View>
    </View>
  )
}
