import { addDays, format } from 'date-fns'
import {
  AllDatesTracker,
  TrackerCheckbox
} from '../pages/sweepOverview/details'
import { checkInitialAndFinalDate, localToUtc } from './utcDateTime'

interface getRangeTimeToFilterI {
  trackers: TrackerCheckbox[]
  executionDate: string | undefined
  timeStartShift?: string
  timeEndShift?: string
  timeStart?: Date | null
  timeEnd?: Date | null
  delta?: boolean
}

const getRangeTimeToFilter = ({
  trackers,
  executionDate,
  timeEndShift,
  timeStartShift,
  timeStart,
  timeEnd,
  delta = true
}: getRangeTimeToFilterI) => {
  let startChange = false
  let endChange = false
  let rangeTimeFilter: AllDatesTracker[] = []
  let deltaEndShift: Date | undefined = undefined
  let deltaStartShift: Date | undefined = undefined
  trackers.forEach((item) => {
    if (item.checked) {
      if (delta) {
        const preparedStartShift = `${localToUtc(
          new Date(executionDate!),
          'yyyy/MM/dd'
        )} ${timeStartShift!}`
        deltaStartShift = new Date(preparedStartShift)
        deltaStartShift.setMinutes(deltaStartShift.getMinutes() - 30)

        const preparedEndShift = `${localToUtc(
          new Date(executionDate!),
          'yyyy/MM/dd'
        )} ${timeEndShift!}`
        deltaEndShift = new Date(preparedEndShift)
        deltaEndShift.setMinutes(deltaEndShift.getMinutes() + 30)

        const endIsBigger = checkInitialAndFinalDate(
          deltaStartShift,
          deltaEndShift
        )
        if (endIsBigger) {
          deltaEndShift = addDays(deltaEndShift, 1)
        }
      }

      item.pontosHistorico.forEach((point, indexPoint) => {
        const date = new Date(point.dataHora)

        if (
          (point.latitudeRoadSnap && point.longitudeRoadSnap) ||
          item.externalTrack
        ) {
          if (delta && deltaEndShift && deltaStartShift) {
            if (date < deltaEndShift && date > deltaStartShift) {
              rangeTimeFilter.push({
                date: date,
                value: indexPoint,
                trackerId: point.codigoRastreador,
                roadSnap: !!(point.latitudeRoadSnap && point.longitudeRoadSnap)
              })
            }
          } else {
            rangeTimeFilter.push({
              date: date,
              value: indexPoint,
              trackerId: point.codigoRastreador,
              roadSnap: !!(point.latitudeRoadSnap && point.longitudeRoadSnap)
            })
          }
        } else if (!delta) {
          rangeTimeFilter.push({
            date: date,
            value: indexPoint,
            trackerId: point.codigoRastreador,
            roadSnap: !!(point.latitudeRoadSnap && point.longitudeRoadSnap),
            specialServiceId: item.specialServiceId ?? undefined
          })
        }
      })
    }
  })

  const dates = rangeTimeFilter.map((item) => item.date)
  if (timeStart && timeStart instanceof Date) {
    const timeFormat = new Date(
      `${localToUtc(new Date(executionDate!), 'yyyy/MM/dd')} ${localToUtc(
        timeStart!,
        'HH:mm:ss'
      )}`
    )
    if (timeFormat <= dates[0] || timeFormat >= dates[dates.length - 1]) {
      startChange = true
      let date = new Date(
        `${localToUtc(new Date(executionDate!), 'yyyy/MM/dd')} ${localToUtc(
          timeStart!,
          'HH:mm:ss'
        )}`
      )
      if (timeFormat >= dates[dates.length - 1]) {
        const formatStartDate = date
        formatStartDate.setDate(formatStartDate.getDate() - 1)
        date = new Date(format(formatStartDate, 'yyyy/MM/dd HH:mm:ss'))
      }
    }
  }
  if (timeEnd && timeEnd instanceof Date) {
    const timeFormat = new Date(
      `${localToUtc(new Date(executionDate!), 'yyyy/MM/dd')} ${localToUtc(
        timeEnd,
        'HH:mm:ss'
      )}`
    )
    if (timeFormat >= dates[dates.length - 1] || timeFormat <= dates[0]) {
      endChange = true
      let date = new Date(
        `${localToUtc(new Date(executionDate!), 'yyyy/MM/dd')} ${localToUtc(
          timeEnd!,
          'HH:mm:ss'
        )}`
      )
      if (timeFormat <= dates[0]) {
        const formatEndDate = date
        formatEndDate.setDate(formatEndDate.getDate() + 1)
        date = new Date(format(formatEndDate, 'yyyy/MM/dd HH:mm:ss'))
      }
    }
  }

  if (!rangeTimeFilter.length) {
    const preparedStartShift = `${localToUtc(
      new Date(executionDate!),
      'yyyy/MM/dd'
    )} ${timeStartShift!}`

    deltaStartShift = new Date(preparedStartShift)
    deltaStartShift.setMinutes(deltaStartShift.getMinutes() - 30)
    rangeTimeFilter.push({
      date: deltaStartShift,
      trackerId: 1,
      value: 0,
      roadSnap: false
    })

    const preparedEndShift = `${localToUtc(
      new Date(executionDate!),
      'yyyy/MM/dd'
    )} ${timeEndShift!}`

    deltaEndShift = new Date(preparedEndShift)
    deltaEndShift.setMinutes(deltaEndShift.getMinutes() + 30)
    rangeTimeFilter.push({
      date: deltaEndShift,
      trackerId: 1,
      value: 0,
      roadSnap: false
    })

    const endIsBigger = checkInitialAndFinalDate(deltaStartShift, deltaEndShift)
    if (endIsBigger) {
      deltaEndShift = addDays(deltaEndShift, 1)
      rangeTimeFilter = []
      rangeTimeFilter.push({
        date: deltaStartShift,
        trackerId: 1,
        value: 0,
        roadSnap: false
      })
      rangeTimeFilter.push({
        date: deltaEndShift,
        trackerId: 1,
        value: 0,
        roadSnap: false
      })
    }
  }

  // Ordena as datas do mais novo ao mais antigo.
  const rangeTimeSorted = rangeTimeFilter.sort((a, b) => {
    const dateA = new Date(a?.date ?? '').getTime()
    const dateB = new Date(b?.date ?? '').getTime()

    if (dateA < dateB) {
      return -1
    } else if (dateA > dateB) {
      return 1
    }
    return 0
  })

  return {
    rangeTimeSorted: rangeTimeSorted.map((item, index) => {
      return {
        ...item,
        value: index
      }
    }),
    endChange,
    startChange
  }
}

export default getRangeTimeToFilter
