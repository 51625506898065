import styled from 'styled-components'
import { colors } from '../../assets/variables'

export const Wrapper = styled.div`
  border: 1px solid ${colors.borderColor};
  background-color: white;
  border-radius: 0.3rem;
`

export const Title = styled.h2`
  color: ${colors.gray900};
  font-size: 18px;
  text-align: center;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
`

export const Body = styled.div`
  padding: 1rem;
  padding-top: 0;
`

export const Footer = styled.div`
  /* border-top: 1px solid ${colors.borderColor}; */
  background-color: ${colors.grayPastel};
  padding: 0.5rem 1rem;
  border-radius: 0 0 0.3rem 0.3rem;
`
