import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'
import { impersonateFormSchema } from '../../utils/validations/authentication'

import { Option } from '../../types/Option'
import { listAllManagerAccount } from '../../app/api/company'
import { listUserByCompanyId } from '../../app/api/user'
import { useSnackbar } from 'notistack'
import { loginImpersonate } from '../../app/api/authenticate'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  removeCurrentContract,
  setAllContracts,
  setCurrentContract
} from '../../app/slices/contractSlice'
import { logout, selectUser, setUser } from '../../app/slices/authSlice'
import { useNavigate } from 'react-router-dom'
import { ModalBase, Button, SelectAutoComplete } from '../index'
import { Box } from '@mui/material'
import { StyledSelectAutoComplete } from '../ImpersonateBox/styles'
import { cleanPermissions } from '../../app/slices/permissionSlice'
import { getPermissionsByModelTracker } from '../../utils/getPermissionsByModelTracker'

export type ImpersonateData = {
  conta: Option
  usuario: Option
}

interface ImpersonateModalProps {
  open: boolean
  close: () => void
  codeCompany?: number | null
}

const ImpersonateModal = ({
  close,
  open,
  codeCompany
}: ImpersonateModalProps) => {
  const user = useAppSelector(selectUser)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [loadingCompany, setLoadingCompany] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [companySelect, setCompanySelect] = useState(false)
  const [configModelTrackers, setConfigModelTrackers] = useState(false)
  const [companyFilter, setCompanyFilter] = useState<number>()
  const [companys, setCompanys] = useState<Option[]>([] as Option[])
  const [usersByCompany, setUsersByCompany] = useState<Option[]>([] as Option[])

  const isLoading = loading || loadingCompany || loadingUser

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()

  const {
    handleSubmit,
    control,
    getValues,
    resetField,
    reset,
    watch,
    formState: { errors }
  } = useForm<ImpersonateData>({
    resolver: yupResolver(impersonateFormSchema)
  })

  const closeModal = () => {
    setIsOpen(false)
    close()
  }

  const handleCancel = () => {
    reset()
    closeModal()
  }

  const onSubmit: SubmitHandler<ImpersonateData> = async (data, event) => {
    event?.preventDefault()
    setLoading(true)

    const params = {
      codigoContaSelecionada: data.conta.value,
      codigoUsuarioSelecionado: data.usuario.value
    }

    try {
      const response = await loginImpersonate(params)

      if (response.success && response.data) {
        if (response.data.email && response.data.token) {
          dispatch(logout())
          const tokenInfos = JSON.parse(response.data.token)
          localStorage.setItem('@lutocar-token', tokenInfos.token)

          // TODO: Guardado na localStorage mas o correto seria ter uma api /me
          dispatch(cleanPermissions())
          localStorage.setItem(
            '@lutocar-user-info',
            JSON.stringify(response.data)
          )
          dispatch(removeCurrentContract())
          localStorage.removeItem('@lutocar-current-contract')

          if (response.data.contratos) {
            dispatch(setAllContracts(response.data.contratos))
            dispatch(
              setCurrentContract(
                response.data.contratos?.find((contract) => contract.matriz) ??
                  response.data.contratos[0]
              )
            )
          }

          dispatch(setUser(response.data))
          enqueueSnackbar(response.message, {
            variant: 'success',
            autoHideDuration: 1000 * 3 // 3 segundos
          })
          reset({})
          closeModal()
          navigate('/')
        }
      } else {
        enqueueSnackbar(response.message ?? 'Credenciais inválidas.', {
          variant: 'error'
        })
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      enqueueSnackbar(
        'Ocorreu um erro interno. Comunique o administrador do sistema!',
        {
          variant: 'error'
        }
      )
    }
  }

  const handleGoConfigModelTrackers = () => {
    closeModal()
    navigate('/model-trackers')
  }

  useEffect(() => {
    setIsOpen(open)
    if (open) {
      getPermissionsByModelTracker(
        user?.identificadorUsuarioImpersonate ?? ''
      ).then((el: any) => {
        if (el) {
          setConfigModelTrackers(el)
        }
      })
    }
  }, [open])

  useEffect(() => {
    const readAllCompany = async () => {
      setLoadingCompany(true)
      const response = await listAllManagerAccount()

      if (response && response.success) {
        let companyList = [] as Option[]

        if (response.data) {
          companyList = response.data.map((item) => {
            return {
              value: item.codigo,
              label: item.nome
            }
          })
        }
        setCompanys(companyList)
        setLoadingCompany(false)
      }

      setLoadingCompany(false)
    }

    readAllCompany()
  }, [])

  useEffect(() => {
    if (codeCompany && companys) {
      reset({
        conta: companys.find((item) => item.value === codeCompany)
      })
    }
  }, [codeCompany, companys])

  useEffect(() => {
    if (codeCompany && usersByCompany) {
      reset({
        conta: companys.find((item) => item.value === codeCompany),
        usuario: usersByCompany[0]
      })
    }
  }, [usersByCompany])

  useEffect(() => {
    const handleUserForCompany = async () => {
      const company = await getValues('conta')
      setCompanySelect(!company)

      if (company && companyFilter !== company.value) {
        setLoadingUser(true)
        resetField('usuario')

        try {
          const response = await listUserByCompanyId(company.value)

          if (response.success) {
            let userList = [] as Option[]

            if (response.data) {
              setCompanyFilter(company.value)
              userList = response.data.map((item) => {
                return {
                  value: item.codigo,
                  label: `${item.nome} - ${item.email}`
                }
              })
              setUsersByCompany(userList)
            }
          }
        } catch (error) {
          enqueueSnackbar(
            'Ocorreu um erro interno. Comunique o administrador do sistema!',
            {
              variant: 'error'
            }
          )
        }

        setLoadingUser(false)
      }
    }

    handleUserForCompany()
  }, [watch('conta')])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBase
        isLoading={loading}
        sx={{
          '.MuiDialog-paper': {
            margin: 0,
            width: '100%'
          }
        }}
        className="container d-flex justify-content-center align-items-center"
        open={isOpen}
        title="Alterar usuário impersonate"
        toggle={() => {
          setIsOpen(false)
          close()
        }}
        footerBackground="grayPastel"
        footer={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Button disabled={isLoading} color="gray" onClick={handleCancel}>
              Cancelar
            </Button>
            {configModelTrackers && (
              <Button
                disabled={isLoading}
                id="go-config-model-tracker"
                type="button"
                color="gray"
                onClick={handleGoConfigModelTrackers}
              >
                Configurar rastreadores
              </Button>
            )}
            <div className="d-flex align-items-center">
              <Button
                disabled={isLoading}
                type="submit"
                color="success"
                onClick={handleSubmit(onSubmit)}
              >
                Fazer Login
              </Button>
            </div>
          </Box>
        }
      >
        <Box>
          <p>
            Selecione uma filial e um usuário para simular um login com esse
            usuário
          </p>
          <Box className="mb-3 mt-3">
            <SelectAutoComplete
              label="Conta"
              name="conta"
              options={companys}
              control={control}
              loading={loadingCompany}
              errorContent={
                (errors.conta?.value as FieldError) ??
                (errors.conta as FieldError)
              }
            />
          </Box>

          <StyledSelectAutoComplete
            label="Usuario"
            name="usuario"
            options={usersByCompany}
            control={control}
            loading={loadingUser}
            disabled={loadingCompany || companySelect}
            errorContent={
              (errors.usuario?.value as FieldError) ??
              (errors.usuario as FieldError)
            }
          />
        </Box>
      </ModalBase>
    </form>
  )
}
export default ImpersonateModal
