import { AccessTime } from '@mui/icons-material'
import { Box, FormControlLabel, Switch, Typography } from '@mui/material'

interface ClockAndReprocessI {
  generationTime: any
  setGenerationTime: React.Dispatch<React.SetStateAction<any>>
}

function GenerationTimeControl(props: ClockAndReprocessI) {
  return (
    <Box
      display="flex"
      alignItems="center"
      px={3}
      py={2}
      justifyContent={'space-between'}
    >
      <FormControlLabel
        label={
          <Typography
            variant="caption"
            className="d-flex align-items-center w-100"
          >
            <AccessTime color={props.generationTime ? 'primary' : 'disabled'} />
          </Typography>
        }
        onChange={(e, checked) => {
          props.setGenerationTime(checked)
        }}
        control={
          <Switch
            size="small"
            value={props.generationTime}
            checked={props.generationTime}
          />
        }
      />
    </Box>
  )
}

export default GenerationTimeControl
