import styled from 'styled-components'
import { Wrapper as GridArea } from '../../components/Grid/styles'

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${GridArea} {
    height: 100%;
  }
`
