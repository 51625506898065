import * as yup from 'yup'

export const createSpecialService = yup.object().shape({
  descricao: yup.string().required('Descrição é obrigatória'),
  identificador: yup.string().required('Identificador é obrigatória'),
  horaInicioTurno: yup.string().required('Obrigatório'),
  horaFimTurno: yup.string().required('Obrigatório'),
  codigoRastreadores: yup
    .array()
    .required('Rastreadores vinculados são obrigatórios')
})
