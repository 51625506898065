import styled, { css } from 'styled-components'

const showFilter = css`
  max-height: 34px;
  padding: 0;
`

export const ViewFilterDetails = styled.div<{ showFilter: boolean }>`
  overflow: hidden;
  background-color: white;
  opacity: 1;
  border-radius: 0.3rem;
  width: 100%;
  max-height: calc(209px);
  transition: 0.3s;
  z-index: 99;
  ${(props) => (!props.showFilter ? showFilter : null)}
`
