import styledEmotion from '@emotion/styled'
import { TextField } from '@mui/material'
import styled from 'styled-components'
import { colors } from '../../assets/variables'

export interface StylesInputProps {
  color?: string
  errorColor?: string
}

export const Wrapper = styled.div``
export const Container = styled.div`
  position: relative;
  height: 35px;
`

export const InputElement = styled.input`
  border: 1px solid ${colors.borderColor};
  height: 35px;
  width: 100%;
  border-radius: 0.15rem;
  padding: 0 0.5rem;
  font-size: 14px;
  color: ${colors.gray};
  background-color: white;
  &:focus,
  &:not(:placeholder-shown) {
    & ~ label {
      font-size: 10px;
      height: auto;
      width: auto;
      background-color: white;
      top: -5px;
      left: 0.5rem;
      padding: 0 3px;
      line-height: 1;
    }
  }
  &:focus {
    border: 2px solid ${colors.primary};
    & ~ label {
      color: ${colors.primary};
    }
  }
  &:disabled {
    background-color: ${colors.grayPastel};
    & ~ label {
      cursor: not-allowed;
    }
  }
`
export const Placeholder = styled.label`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  font-size: 12px;
  font-weight: 500;
  z-index: 9;
  user-select: none;
  transition: 0.05s;
  color: ${colors.grayLight};
  cursor: text;
`
export const Error = styled.small`
  font-size: 12px;
  color: ${(props: StylesInputProps) =>
    props.color !== undefined
      ? colors[props.color as keyof typeof colors]
      : colors.gray};
`
export const ToggleCharacters = styled.span`
  position: absolute;
  height: 35px;
  width: 35px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  cursor: pointer;
  svg {
    height: 15px;
    width: 15px;
    color: ${colors.grayLight};
  }
`

export const StyledTextField = styledEmotion(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-disabled fieldset': {
      borderColor: colors.gray800,
      color: colors.grayDisabled
    }
  },
  '& .Mui-disabled': {
    backgroundColor: colors.grayDisabled,
    opacity: '1',
    color: colors.gray800,
    cursor: 'not-allowed'
  },
  '& .MuiInputLabel-root': {
    '&.Mui-disabled': {
      backgroundColor: colors.grayDisabled,
      opacity: '1',
      color: colors.gray800,
      marginTop: '-7px',
      borderBottom: '1px solid',
      cursor: 'not-allowed !important'
    }
  },
  input: {
    ':disabled': {
      backgroundColor: colors.grayDisabled,
      opacity: '1',
      color: colors.gray800,
      cursor: 'not-allowed',
      pointerEvents: 'all'
    }
  },
  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
    {
      '-webkit-appearance': 'none',
      margin: 0
    },
  '& input[type=number]': {
    '-moz-appearance': 'textfield'
  }
})
