import React from 'react'
import { Typography, Box } from '@mui/material'
import { FlagColor } from './styles'

interface ShapeTwins {
  menuChoice: string
  destinationUuid: string | null
  originUuid: string
}

interface ColorPair {
  dark: string
  light: string
}

interface GroupProps {
  groups: ShapeTwins[][]
  colorByIndex: (index: number) => string
  colorByParallel: (guideId: string) => ColorPair
}

const GroupComponent: React.FC<GroupProps> = ({
  groups,
  colorByIndex,
  colorByParallel
}) => {
  // Separar itens com menuChoice "Parallel" dos outros
  const parallelItems: ShapeTwins[] = []
  const otherItems: ShapeTwins[][] = []

  groups.forEach((group) => {
    const parallel = group.filter((item) => item.menuChoice === 'Parallel')
    const others = group.filter((item) => item.menuChoice !== 'Parallel')
    parallelItems.push(...parallel)
    if (others.length > 0) {
      otherItems.push(others)
    }
  })

  return (
    <>
      {otherItems.map((group, index) => (
        <React.Fragment key={index}>
          <Typography variant="caption" fontWeight={600}>
            {group.find((el) => el.menuChoice === 'Twin')
              ? `Sequencia de transição ${index + 1}`
              : '...'}
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {group
              .filter((item) => item.destinationUuid !== null)
              .map((item, groupIndex, groupsArray) => {
                const existDestination = groupsArray.findIndex(
                  (patch) => patch.destinationUuid === item.destinationUuid
                )

                if (existDestination !== -1) {
                  groupIndex = existDestination
                }
                return (
                  <FlagColor
                    key={groupIndex}
                    color={colorByIndex(groupIndex)}
                  />
                )
              })}
          </Box>
        </React.Fragment>
      ))}
      {parallelItems.length > 0 && (
        <React.Fragment>
          <Typography variant="caption" fontWeight={600}>
            Guias Paralelas
          </Typography>
          <Box display="flex" flexWrap="wrap">
            {parallelItems
              .filter((item) => item.destinationUuid !== null)
              .map((item, index) => (
                <FlagColor
                  key={index}
                  color={colorByParallel(item.originUuid).light}
                />
              ))}
          </Box>
        </React.Fragment>
      )}
    </>
  )
}

export default GroupComponent
