import { useEffect, useState } from 'react'

import { useSnackbar } from 'notistack'

import { Box, Typography } from '@mui/material'
import { ModalBase, Button } from '../index'
import { ArchiveProps } from '../../types/Global'
import { ErrorOutline } from '@mui/icons-material'
import Response from '../../app/api/Response'

interface ConfirmArquiveData {
  status: string | 'Ativo' | 'Arquivado'
  code: number | null
}
interface ConfirmArchiveProps {
  title?: string
  screenName: string
  open: boolean
  close: () => void
  data: ConfirmArquiveData
  serviceFunction: (value: ArchiveProps) => Promise<Response<any>>
  runNow?: boolean
}

const ConfirmArchive = ({
  screenName,
  data,
  open,
  close,
  serviceFunction,
  runNow = false
}: ConfirmArchiveProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleArquive = async () => {
    try {
      if (data.code) {
        setLoading(true)
        const response = await serviceFunction({ codigo: data.code })

        if (response.success) {
          if (response.message) {
            enqueueSnackbar(response.message, {
              variant: 'success'
            })
          } else if (Array.isArray(response.data)) {
            response.data.map((dataItem: any) => {
              enqueueSnackbar(dataItem.mensagem, {
                variant: 'success'
              })
            })
          }
        } else {
          if (response.message) {
            enqueueSnackbar(response.message, {
              variant: 'error'
            })
          } else if (Array.isArray(response.data)) {
            response.data.map((dataItem: any) => {
              enqueueSnackbar(dataItem.mensagem, {
                variant: 'error'
              })
            })
          }
        }
      }
      setLoading(false)
      setIsOpen(false)
      close()
    } catch (error) {
      setLoading(false)
      enqueueSnackbar(
        'Ocorreu um erro interno. Comunique o administrador do sistema!',
        {
          variant: 'error'
        }
      )
    }
  }

  useEffect(() => {
    if (runNow) {
      handleArquive()
    }
  }, [runNow])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const textButton = data.status === 'Ativo' ? 'Arquivar' : 'Desarquivar'
  const isActive = data.status === 'Ativo'
  return (
    <ModalBase
      title={`${textButton} ${screenName}`}
      open={isOpen}
      isLoading={loading}
      toggle={() => {
        setIsOpen(false)
        close()
      }}
      fullWidth
      maxWidth={'sm'}
      footer={
        <Box display="flex" justifyContent="space-between" gap={1}>
          <Button
            disabled={loading}
            color="gray"
            onClick={() => {
              setIsOpen(false)
              close()
            }}
          >
            Cancelar
          </Button>
          <Button
            id="toggleArchive"
            disabled={loading}
            color={isActive ? 'warning' : 'info'}
            type="submit"
            onClick={handleArquive}
          >
            {textButton}
          </Button>
        </Box>
      }
    >
      <Box className="container">
        <Typography className="d-flex align-items-start mb-3">
          <ErrorOutline
            color={isActive ? 'warning' : 'info'}
            className="me-3"
          />
          {isActive
            ? 'Ao efetuar essa ação, esse item ficará oculto na visualização principal.'
            : 'Ao executar essa ação, este item voltará a ficar disponível a todos os usuários, de acordo com seu nível de acesso.'}
        </Typography>
        <Typography fontWeight={600} textAlign="center">
          Tem certeza que deseja {textButton.toLocaleLowerCase()}?
        </Typography>
      </Box>
    </ModalBase>
  )
}
export default ConfirmArchive
