import * as yup from 'yup'
import { validateImei } from './generical'

export const createTrackerSchema = yup.object().shape({
  imei: yup
    .string()
    .required('IMEI é obrigatório')
    .test('imei-validate', '', function (value) {
      if (!validateImei(value)) {
        return this.createError({
          message: `IMEI inválido`
        })
      }
      return true
    }),
  codigoModeloRastreador: yup
    .object()
    .nullable()
    .shape({
      label: yup.string().required('Codigo Modelo é obrigatório'),
      value: yup
        .number()
        .notOneOf([-1], 'Codigo Modelo é obrigatório')
        .required()
    })
    .test(
      'model-empty',
      'Modelo do rastreador é obrigatório',
      function (field: any) {
        if (field && field.value !== -1) {
          return true
        }
        return false
      }
    )
})

export const editTrackerSchema = yup.object().shape({
  imei: yup
    .string()
    .required('IMEI é obrigatório')
    .test('imei-validate', '', function (value) {
      if (!validateImei(value)) {
        return this.createError({
          message: `IMEI inválido`
        })
      }
      return true
    })
})
