export const colors = {
  gray100: '#D0D5D8',
  gray200: '#B6BCC1',
  gray300: '#A3ABB2',
  gray400: '#8F9CAA',
  gray500: '#8793A1',
  gray600: '#808C99',
  gray700: '#77828D',
  gray800: '#717B85',
  gray900: '#5c6269',

  grayDisabled: '#f8f8f8',

  grayPastel: '#F8F8F8',
  grayLight: '#848484',
  gray: '#626262',
  grayDark: '#383838',

  primaryPastel: '#D9EEF5',
  primaryLight: '#35B2D9',
  primary: '#009ACA',
  primaryDark: '#0084AD',

  successPastel: '#E9F8F0',
  successLight: '#2CDB7B',
  success: '#28C76F',
  successDark: '#23AD61',

  systemPastel: '#E4EAF5',
  systemLight: '#193666',
  system: '#1D3051',
  systemDark: '#0E1D38',

  warningPastel: '#FCF2E8',
  warningLight: '#FCB673',
  warning: '#FF9F43',
  warningDark: '#FC8F27',

  dangerPastel: '#F5E9EA',
  dangerLight: '#D05363',
  danger: '#C4162D',
  dangerDark: '#AC0219',

  pink: '#DA64ED',

  blackTransparent: 'rgba(0,0,0,0.2)',
  blackTransparent50: 'rgba(0,0,0,0.5)',
  borderColor: '#ccc',
  boxShadow: '0 0 10px rgba(0,0,0,0.2)',

  textColor: '#000000',

  white: '#FFF'
}
