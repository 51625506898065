import { createTheme } from '@mui/material/styles'
import { colors } from './assets/variables'
import { ptBR } from '@mui/material/locale'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    gray?: Palette['primary']
    textDefault?: Palette['primary']
  }
  interface PaletteOptions {
    gray?: PaletteOptions['primary']
    textDefault?: PaletteOptions['primary']
    white?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true
    textDefault: true
    white: true
  }
}

export const theme = createTheme(
  {
    components: {
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: colors.primaryPastel,
            color: colors.primary,
            height: `35px`,
            width: `35px`,
            fontSize: `12px`,
            fontWeight: '600'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            display: 'flex',
            textTransform: 'none',
            borderRadius: '0.3rem',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 14px',
            fontSize: '13px',
            fontFamily: 'Montserrat',
            height: '35px',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            ':disabled': {
              opacity: '0.6',
              color: colors.grayLight,
              cursor: 'not-allowed',
              pointerEvents: 'all'
            },
            '@media (max-width: 768px)': {
              fontSize: '10px',
              padding: '5px 7px'
            }
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: colors.textColor
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ':disabled': {
              backgroundColor: colors.grayLight,
              opacity: '0.5',
              cursor: 'not-allowed',
              pointerEvents: 'all'
            }
          },
          input: {
            height: '17px',
            padding: '8px' // Ajustar conforme necessário para se adequar ao design
          }
        }
      }
    },
    palette: {
      primary: {
        main: colors.primary,
        dark: colors.primaryDark,
        light: colors.primaryLight
      },
      success: {
        main: colors.success,
        dark: colors.successDark,
        light: colors.successLight
      },
      error: {
        main: colors.danger,
        dark: colors.dangerDark,
        light: colors.dangerLight
      },
      warning: {
        main: colors.warning,
        dark: colors.warningDark,
        light: colors.warningLight
      },
      gray: {
        main: colors.gray,
        dark: colors.grayDark,
        light: colors.grayLight
      },
      textDefault: {
        main: colors.textColor
      },
      white: {
        main: '#fff'
      }
    },
    typography: {
      fontFamily: ['Montserrat, sans-serif'].join(',')
    }
  },
  ptBR
)
