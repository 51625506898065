import { View, Text, StyleSheet } from '@react-pdf/renderer'
import { colors } from '../../../assets/variables'

const borderColor = colors.gray
const borderWidth = 0.5

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    backgroundColor: '#FFFF',
    borderBottomWidth: borderWidth,
    alignItems: 'center',
    minHeight: 12,
    maxHeight: 17,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
    fontSize: 4,
    color: colors.gray900,
    paddingLeft: 2,
    paddingRight: 2
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: borderWidth,
    borderLeft: borderWidth,
    borderColor: borderColor
  },
  textContainer: {
    borderRightColor: borderColor,
    color: colors.gray900,
    fontSize: 5
  },
  flag: {
    height: 5,
    width: 9,
    marginRight: 2
  }
})

interface RowsGridReportI {
  rows: {
    idSetor: string
    setor: string
    dataExecucao: string
    turno: string
    planejado: string
    executado: string
    executadoPorcentagem: string
    naoExecutado: string
    naoExecutadoPorcentagem: string
    tempo: string
    pagamento: string
  }[]
  columns: {
    name: string
    width: string
    textAlign: 'left' | 'center' | 'right' | 'justify' | undefined
  }[]
}

export const RowsGridReport = ({ rows, columns }: RowsGridReportI) => {
  return (
    <View style={styles.tableContainer}>
      {rows.map((item, indexRow) => {
        const values = Object.values(item)
        const color = values[0]
        values.splice(0, 1)

        return (
          <View key={indexRow} style={styles.container}>
            {values.map((value, index) => {
              if (color && index == 0) {
                return (
                  <View
                    key={indexRow}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: 57
                    }}
                  >
                    {color ? (
                      <View
                        style={{ ...styles.flag, backgroundColor: color }}
                      />
                    ) : null}
                    <Text
                      style={{
                        ...styles.textContainer,
                        textDecoration:
                          values[2] === '---' ? 'line-through' : 'none',
                        width: columns[index].width,
                        textAlign: columns[index].textAlign
                      }}
                    >
                      {value}
                    </Text>
                  </View>
                )
              }
              return (
                <Text
                  key={indexRow}
                  style={{
                    ...styles.textContainer,
                    width: columns[index].width,
                    textAlign: columns[index].textAlign,
                    paddingLeft: 2
                  }}
                >
                  {value}
                </Text>
              )
            })}
          </View>
        )
      })}
    </View>
  )
}
