import { useEffect, useState } from 'react'
import { Button, InputBase, ModalBase } from '../../components/index'

import { yupResolver } from '@hookform/resolvers/yup'
import { FieldError, SubmitHandler, useForm } from 'react-hook-form'

import { useSnackbar } from 'notistack'
import { Box, Grid } from '@mui/material'
import * as yup from 'yup'

import {
  EditModelTrackerProps,
  CreateModelTrackerProps,
  createModelTracker,
  editModelTracker
} from '../../app/api/modelTracker'
import { useAppSelector } from '../../app/hooks'
import { selectCurrentContract } from '../../app/slices/contractSlice'

export const createModelTrackerSchema = yup.object().shape({
  identificador: yup.string().required('Identificador é obrigatório'),
  arquivoConfiguracao: yup.string().required('Configuração é obrigatório')
})

export interface TrackProps {
  open: boolean
  close: () => void
  isChange: boolean
  tracker?: EditModelTrackerProps | null
}

export const ModelTracker = ({
  open,
  close,
  isChange,
  tracker
}: TrackProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const currentContract = useAppSelector(selectCurrentContract)
  const { enqueueSnackbar } = useSnackbar()

  const { register, handleSubmit, formState, resetField } =
    useForm<CreateModelTrackerProps>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver: yupResolver(createModelTrackerSchema),
      defaultValues: isChange
        ? {
            arquivoConfiguracao: tracker?.arquivoConfiguracao,
            identificador: tracker?.identificador
          }
        : {}
    })
  const { errors } = formState

  console.log('errors', errors)

  const closeAll = () => {
    setIsOpen(false)
    resetField('identificador')
    resetField('arquivoConfiguracao')
    close()
  }

  const handleSaveTraker: SubmitHandler<CreateModelTrackerProps> = async (
    values
  ) => {
    try {
      const params = {
        ...values,
        codigoContrato: currentContract?.codigo!
      }
      setLoading(true)
      const response = await createModelTracker(params)

      if (response.success && response.data) {
        if (!response.message && response.data[0].mensagem) {
          enqueueSnackbar(response.data[0].mensagem, {
            variant: 'success'
          })
        } else {
          enqueueSnackbar(response.message, {
            variant: 'success'
          })
        }
      } else {
        enqueueSnackbar(response.data[0].mensagem, {
          variant: 'error'
        })
      }
      closeAll()
      setLoading(false)
    } catch (error) {
      closeAll()
      setLoading(false)
      enqueueSnackbar(
        'Ocorreu um erro interno. Comunique o administrador do sistema!',
        {
          variant: 'error'
        }
      )
    }
  }

  const handleEditModelTraker: SubmitHandler<CreateModelTrackerProps> = async (
    values: any
  ) => {
    try {
      const params = {
        codigo: tracker?.codigo!,
        ...values
      }

      setLoading(true)
      const response = await editModelTracker(params)

      if (response.success) {
        enqueueSnackbar(response.message, {
          variant: 'success'
        })
      } else {
        if (!response.message && response.data[0].mensagem) {
          enqueueSnackbar(response.data[0].mensagem, {
            variant: 'error'
          })
        } else {
          enqueueSnackbar(response.message, {
            variant: 'error'
          })
        }
      }
      closeAll()
      setLoading(false)
    } catch (error) {
      closeAll()
      setLoading(false)
      enqueueSnackbar(
        'Ocorreu um erro interno. Comunique o administrador do sistema!',
        {
          variant: 'error'
        }
      )
    }
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  // TODO: Rever melhor maneira de fezer.
  useEffect(() => {
    const handleClickSaveButton = (event: any) => {
      if (event.code === 'Enter' || event.keyCode === 13) {
        document.getElementById('save-button')?.click()
        return
      }
    }
    document.addEventListener('keydown', handleClickSaveButton)
  }, [])

  return (
    <form
      onSubmit={handleSubmit(
        isChange ? handleEditModelTraker : handleSaveTraker
      )}
    >
      <ModalBase
        title={
          isChange
            ? 'Editar configuração geral do Rastreador'
            : 'Cadastrar configuração geral do Rastreador'
        }
        open={isOpen}
        toggle={() => {
          closeAll()
        }}
        fullWidth
        maxWidth={'md'}
        footer={
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Button
              disabled={loading}
              color="gray"
              onClick={() => {
                closeAll()
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={loading}
              color={isChange ? 'info' : 'success'}
              onClick={handleSubmit(
                isChange ? handleEditModelTraker : handleSaveTraker
              )}
            >
              {isChange ? 'Salvar' : 'Cadastrar'}
            </Button>
          </Box>
        }
      >
        <>
          {isChange ? (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <InputBase
                  id="identificador"
                  size="small"
                  variant="outlined"
                  label="Modelo do Rastreador"
                  defaultValue={tracker?.identificador}
                  fullWidth
                  autoFocus
                  autoComplete="off"
                  errorContent={errors.identificador as FieldError}
                  {...register('identificador')}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <InputBase
                  id="arquivoConfiguracao"
                  size="small"
                  variant="outlined"
                  label="Comandos de Configuração"
                  type="text"
                  fullWidth
                  defaultValue={tracker?.arquivoConfiguracao}
                  multiline={true}
                  rows={20}
                  maxLength={7000}
                  autoFocus
                  autoComplete="off"
                  errorContent={errors.arquivoConfiguracao as FieldError}
                  {...register('arquivoConfiguracao')}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} mt={1}>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <InputBase
                  id="identificador"
                  size="small"
                  variant="outlined"
                  label="Modelo do Rastreador"
                  fullWidth
                  autoFocus
                  autoComplete="off"
                  errorContent={errors.identificador as FieldError}
                  {...register('identificador')}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <InputBase
                  id="arquivoConfiguracao"
                  size="small"
                  variant="outlined"
                  label="Comandos de Configuração"
                  type="text"
                  fullWidth
                  multiline={true}
                  rows={20}
                  maxLength={7000}
                  autoFocus
                  autoComplete="off"
                  errorContent={errors.arquivoConfiguracao as FieldError}
                  {...register('arquivoConfiguracao')}
                />
              </Grid>
            </Grid>
          )}
        </>
      </ModalBase>
    </form>
  )
}
