import { AxiosError } from 'axios'
import Response from '../api/Response'
import { listPaginationQuery } from '../../types/Global'
import api from './api'

export interface CreateModelTrackerProps {
  identificador: string
  arquivoConfiguracao: string
}

export interface EditModelTrackerProps {
  codigo: number
  identificador: string
  arquivoConfiguracao: string
}

export interface resultModelTrackerList {
  arquivoConfiguracao: string
  codigo: number
  dataCadastro: string
  identificador: string
  usuarioCadastro: string
}

export const listAllModelTrackers = async ({
  codigoContrato = null,
  arquived = false,
  orderName = 'Codigo',
  orderDirection = 'asc',
  skip = 0,
  take = 20,
  query = ''
}: listPaginationQuery): Promise<Response<resultModelTrackerList[]>> => {
  try {
    const iscodigoContrato = !!codigoContrato
    const params = `
        ${
          iscodigoContrato ? `CodigoContrato=${codigoContrato}&` : ''
        }ordenacao[0].ordenacao=${orderName}&ordenacao[0].direcaoOrdenacao=${orderDirection}
        &Arquivados=${arquived}
        &Skip=${skip}
        &Take=${take}
        &Query=${query}
      `.trim()

    const response = await api.get(`ModeloRastreador/Listar?${params}`)

    return Response.fromDataPagination(
      response.data.data as resultModelTrackerList[],
      response.status,
      response.data.campos,
      response.data.count,
      response.data.ordenacaoAtual,
      response.data.count,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const createModelTracker = async (
  params: CreateModelTrackerProps
): Promise<Response<any>> => {
  try {
    const response = await api.post(`ModeloRastreador/Salvar`, params)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const editModelTracker = async (
  params: EditModelTrackerProps
): Promise<Response<any>> => {
  try {
    const response = await api.put(`ModeloRastreador/Alterar`, params)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const readHistoryModelTrackers = async (
  codigoModeloRastreador: string
): Promise<Response<resultModelTrackerList[]>> => {
  try {
    const response = await api.get(
      `ModeloRastreador/BuscarHistoricoAlteracao?CodigoModeloRastreador=${codigoModeloRastreador}`
    )

    return Response.fromDataPagination(
      response.data.data as resultModelTrackerList[],
      response.status,
      response.data.campos,
      response.data.count,
      response.data.ordenacaoAtual,
      response.data.count,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
