import { getUserHavePermissionModelTracker } from '../app/api/user'

export const checkPermissionsByLocalStorage = () => {
  if (localStorage.getItem('@lutocar-user-info')) {
    const userInfo = JSON.parse(
      localStorage.getItem('@lutocar-user-info') ?? ''
    )
    return userInfo.configModelTrackers
  }
}

const checkPermissionsByBackEnd = async (email: string) => {
  const result = await getUserHavePermissionModelTracker()

  if (result.success && result.data) {
    if (result.data.find((el) => el.email === email)) {
      const user = JSON.parse(localStorage.getItem('@lutocar-user-info') ?? '')
      localStorage.setItem(
        '@lutocar-user-info',
        JSON.stringify({
          ...user,
          configModelTrackers: true
        })
      )
      return true
    }
  }
}

export const getPermissionsByModelTracker = async (
  email: string
): Promise<boolean | null | undefined> => {
  const isLocalStoragePermission = checkPermissionsByLocalStorage()
  if (isLocalStoragePermission) {
    return isLocalStoragePermission
  }
  const isApiPermission = await checkPermissionsByBackEnd(email)
  return isApiPermission
}
