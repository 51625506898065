import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import { store } from './app/store'

import { ThemeProvider } from '@mui/material/styles'
import { theme } from './mui-theme'
import { SnackbarProvider } from 'notistack'
import App from './routes'
import { BrowserRouter } from 'react-router-dom'
import { Suspense } from 'react'
import GlobalLoading from './components/LoadingGlobal'

import '@inlog/react-components/src/assets/styles/icons.css'
import '@inlog/react-components/src/global.css'
import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'

const container = document.getElementById('root')
const root = createRoot(container!)

const isDevelopment = process.env.REACT_APP_IS_DEVELOPMENT?.trim() === 'true'

if (isDevelopment) {
  const protocol = window.location.protocol
  const hostname = window.location.hostname
  const port = window.location.port
  const url = `${protocol}//${hostname}:${port}`

  const secretKey = 'Inlog@2024-LocalAuth'
  const hash = CryptoJS.HmacSHA256(url, secretKey).toString()

  Cookies.set('inlog-development', 'true', {
    domain: '.inlog.com.br',
    expires: 30
  })
  Cookies.set('inlog-development-url', url, {
    domain: '.inlog.com.br',
    expires: 30
  })
  Cookies.set('inlog-development-hash', hash, {
    domain: '.inlog.com.br',
    expires: 30
  })
}

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Suspense fallback={<GlobalLoading />}>
            <App />
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
)
