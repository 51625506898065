import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

export interface InfosToImport {
  sectorIdField?: string
  descriptionField?: string
  shiftField?: string
  schedulingField?: string
  rows: RowsProps[]
  geometry: any[]
}

interface RowsProps {
  idSector?: any
  descriptionSector?: any
  shift?: any
  scheduling?: any
}

interface FilesInfosProps {
  name: string
}

export interface SectorImportState {
  openModal?: boolean
  files: FilesInfosProps[]
  infosToImport: InfosToImport
}

const initialState: SectorImportState = {
  openModal: false,
  files: [],
  infosToImport: {
    descriptionField: undefined,
    sectorIdField: undefined,
    rows: [],
    geometry: []
  }
}

export const sectorImportSlice = createSlice({
  name: 'sectorImport',
  initialState,
  reducers: {
    clear: () => {
      return initialState
    },
    setFilesName: (state, action: PayloadAction<FilesInfosProps[]>) => {
      const joinFilesName = [...state.files, ...action.payload]
      return {
        ...state,
        files: joinFilesName
      }
    },
    setInfosToImport: (state, action: PayloadAction<InfosToImport>) => {
      return {
        ...state,
        infosToImport: action.payload
      }
    },
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        openModal: action.payload
      }
    }
  }
})

export const { clear, setFilesName, setInfosToImport, setOpenModal } =
  sectorImportSlice.actions

export const selectInfosToImport = (state: RootState) => {
  return state.importSector.infosToImport
}

export const selectFileNameToImport = (state: RootState) => {
  return state.importSector.files.map((file) => file.name)
}

export const selectOpenModal = (state: RootState) => {
  return state.importSector.openModal ?? false
}

export default sectorImportSlice.reducer
