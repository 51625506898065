import styled, { css } from 'styled-components'
import { colors } from '../../assets/variables'

export const ListItems = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`
const active = css`
  border-top: 4px solid ${colors.primary};
`

export const Item = styled.div<{ active: boolean }>`
  width: 100%;
  border-top: 4px solid ${colors.grayPastel};
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.3rem;
  padding: 1rem;
  transition: 0.1s;
  cursor: pointer;
  ${(props: { active: boolean }) => (props.active ?? false ? active : null)}
  &:hover {
    transform: scale(1.05);
  }
`

export const Figure = styled.figure`
  position: relative;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  border-radius: 0.3rem;
  background-color: ${colors.grayPastel};
  padding: 0.7rem;
`

export const Icon = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
`

export const Upload = styled.img`
  height: 25px;
  width: 25px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  right: -5px;
  bottom: -5px;
`

export const ItemTitle = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin-left: 1rem;
  color: ${colors.primary};
  position: relative;
`

export const Description = styled.p`
  font-size: 14px;
  margin-top: 1rem;
  color: ${colors.gray900};
`
