import { getTypePointSweeping } from '../../../enums/typePointSweeping'
import { mapHooks } from '../../../map'
import { toFixed } from '../../../utils/toFixedNumber'
import { utcToLocal } from '../../../utils/utcDateTime'
import { Map } from '@inlog/inlog-maps/lib'

const renderRadius = (radius: string | null) => {
  if (!radius) return ''
  return `<li><strong>Raio:</strong> ${radius} metros</li>`
}

const renderPopup = (
  currentMap: Map | null,
  trackerId: string,
  startGeneration: string,
  bateryLevel: string,
  stopedTime: string,
  radius: string | null,
  latitude: number,
  longitude: number,
  typePoint: 'PontoDeParada' | 'InicioDeDeslocamento',
  velocidadeMedia: number,
  distanciaProximoPonto: number
) => {
  const date = new Date(startGeneration)
  const radiusItem = renderRadius(radius)
  mapHooks.renderPopup(
    currentMap,
    `<div class="popup-info">
        <ul>
          <li><strong>ID Rastreador:</strong> ${trackerId}</li>
          <li><strong>Nivel da bateria:</strong> ${bateryLevel}%</li>
          <li><strong>Tipo:</strong> ${getTypePointSweeping(typePoint)}</li>
          <li><strong>Data de geração:</strong> ${utcToLocal(
            date,
            'dd/MM/yyyy'
          )}</li>
          <li><strong>Horário de geração:</strong> ${utcToLocal(
            date,
            'HH:mm:ss'
          )}</li>
          <li><strong>Tempo de deslocamento:</strong> ${
            stopedTime ? stopedTime : '--:--'
          }</li>
          <li><strong>Dist. espacial px. pto:</strong> ${
            distanciaProximoPonto ? toFixed(distanciaProximoPonto, 2) : '--'
          } metros</li>
          ${radiusItem}
          <li><strong>Velocidade média:</strong> ${toFixed(
            velocidadeMedia,
            2
          )} metros/min</li>
        </ul>
      <div>`,
    [latitude, longitude]
  )
}

export default renderPopup
