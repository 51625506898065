import { Menu, Minimize, Print } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'

interface ToolsHeaderI {
  showInfo: boolean
  setShowInfo: React.Dispatch<React.SetStateAction<boolean>>
  handleGenerateReports: () => Promise<void>
}

function ToolsHeader(props: ToolsHeaderI) {
  return (
    <Box width="100%" display="flex" justifyContent={'flex-end'}>
      <Box display="flex" alignItems="center">
        {props.showInfo && (
          <Box display="flex" alignItems="center">
            <Tooltip title="Gerar relatório" placement="top" className="me-2">
              <IconButton
                className="me-2"
                onClick={() => props.handleGenerateReports()}
              >
                <Print />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <IconButton
        size="small"
        onClick={() => props.setShowInfo((prevShow: boolean) => !prevShow)}
        color="inherit"
      >
        {props.showInfo ? <Minimize /> : <Menu />}
      </IconButton>
    </Box>
  )
}

export default ToolsHeader
