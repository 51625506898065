import { useEffect, useState } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import type { Control } from 'react-hook-form'
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Tooltip
} from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

interface Option {
  label: string
  value: number
}
interface SelectAutoCompleteProps {
  id?: string
  onSearch?: (search: string) => void
  onPagination?: () => void
  name: string
  label: string
  options: Option[]
  disableCloseOnSelect?: boolean
  control: Control<any>
  className?: string
  defaultOptions?: Option[] | Option | null
  loading?: boolean
  errorContent?: FieldError
  multiple?: boolean
  hint?: string
  disabled?: boolean
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const SelectCheckbox = ({
  id,
  onSearch,
  onPagination,
  name,
  label,
  className,
  options,
  defaultOptions,
  control,
  errorContent,
  disabled = false,
  disableCloseOnSelect = true,
  loading = false,
  multiple = true,
  hint
}: SelectAutoCompleteProps) => {
  const [selected, setSelected] = useState<any>()
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSelected(defaultOptions ?? [])
  }, [])

  const handleChangeCheckbox = (option: Option[]) => {
    setSelected([...option])
  }
  const handleChangeCheckboxOnce = (option: Option) => {
    setSelected(option)
  }

  const checkOption = (option: any) => {
    let check = false
    if (!multiple) {
      check = selected?.value === option?.value
    } else {
      const arrays = selected as Option[]
      check = arrays.some((c) => c.value === option.value)
    }
    return check
  }

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      onSearch?.(search)
    }, 500)
    return () => clearTimeout(delaySearch)
  }, [search])

  const renderAutocomplete = (onChange: any) => (
    <Autocomplete
      id={id}
      disabled={disabled}
      multiple={multiple}
      size="small"
      disableCloseOnSelect={disableCloseOnSelect}
      className={className}
      options={options}
      loading={loading}
      loadingText="Carregando..."
      getOptionLabel={(option) => option.label ?? ''}
      onChange={(_, option) => {
        if (multiple && option) {
          onChange([...(option as Option[])])
          handleChangeCheckbox(option as Option[])
        } else {
          onChange(option)
          handleChangeCheckboxOnce(option as Option)
        }
      }}
      isOptionEqualToValue={(option, optionValue) =>
        option.value === optionValue.value
      }
      limitTags={4}
      getLimitTagsText={(more) => `+${more}`}
      disableListWrap
      value={selected ?? []}
      onBlur={() => setSearch('')}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
          if (
            Math.floor(((scrollTop + clientHeight) / scrollHeight) * 100 + 1) >=
            100
          ) {
            const delayPagination = setTimeout(() => {
              onPagination?.()
            }, 500)
            return () => clearTimeout(delayPagination)
          }
        }
      }}
      renderOption={(props, option: any) => {
        return (
          <li {...props} key={option.value}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={checkOption(option)}
            />
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          error={!!errorContent}
          {...params}
          disabled={disabled}
          label={label}
          variant="outlined"
          value={search}
          autoComplete={'off'}
          type="search"
          onChange={(e) => setSearch(e.target.value)}
          InputLabelProps={{ shrink: true, autoCapitalize: '' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => {
        return (
          <FormControl fullWidth error={!!errorContent}>
            {hint ? (
              <Tooltip title={hint}>{renderAutocomplete(onChange)}</Tooltip>
            ) : (
              renderAutocomplete(onChange)
            )}
            {errorContent && (
              <FormHelperText>{errorContent?.message}</FormHelperText>
            )}
          </FormControl>
        )
      }}
    />
  )
}
export default SelectCheckbox
