import { useEffect, useState } from 'react'

import { Grid } from '../../components/index'

import { useSnackbar } from 'notistack'

import { Wrapper } from './styles'
import { columns } from './columns'
import {
  EditModelTrackerProps,
  listAllModelTrackers
} from '../../app/api/modelTracker'
import { ModelTracker } from './modelTracker'
import { useAppSelector } from '../../app/hooks'
import { selectCurrentContract } from '../../app/slices/contractSlice'
import { Tracker as TrackerType } from '../../types/Tracker'
import { userIsImpersonate } from '../../app/slices/authSlice'
import { HistoryModelTracker } from './historyModelTracker'
import { utcToLocal } from '../../utils/utcDateTime'

interface Row {
  columns: object[]
  id: string
  row: TrackerType
}

const ModelTrackers = () => {
  const { enqueueSnackbar } = useSnackbar()
  const currentContract = useAppSelector(selectCurrentContract)
  const isImpersonate = useAppSelector(userIsImpersonate)

  // Modais
  const [openContract, setOpenContract] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)
  const [changeTracker, setChangeTracker] = useState(false)
  const [changeHistory, setChangeHistory] = useState(false)

  //  Grid
  const [loading, setLoading] = useState(true)
  const [archived, setArchived] = useState<boolean>(false)
  const [rows, setRows] = useState<any[]>([])
  const [trackerChange, setTrackerChange] =
    useState<EditModelTrackerProps | null>(null)
  const [trackerHistory, setTrackerHistory] =
    useState<EditModelTrackerProps | null>(null)
  const [filter, setFilter] = useState<string>()

  // Paginação
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)

  // Ações
  const handleActions = async (action: string, row: Row | null) => {
    switch (action) {
      case 'add':
        setChangeTracker(false)
        setOpenHistory(false)
        setOpenContract(true)
        break
      case 'archived':
        setArchived(!archived)
        break
      case 'edit':
        setTrackerChange(row?.row as any)
        setChangeTracker(true)
        setOpenContract(true)
        setChangeHistory(false)
        setOpenHistory(false)
        break
      case 'history':
        setTrackerHistory(row?.row as any)
        setChangeHistory(true)
        setOpenHistory(true)
        setChangeTracker(false)
        setOpenContract(false)
        break
    }
  }

  const listTrackers = async () => {
    if (currentContract) {
      setLoading(true)
      try {
        const response = await listAllModelTrackers({
          codigoContrato: currentContract?.codigo,
          arquived: archived,
          query: filter,
          take: rowsPerPage,
          skip: rowsPerPage * page
        })

        if (response.success && response.data) {
          const trackerRows = response.data.map((tracker) => {
            return {
              ...tracker,
              id: `${tracker.codigo}`,
              dataCadastro: utcToLocal(
                new Date(tracker.dataCadastro),
                'dd/MM/yyyy HH:mm'
              )
            }
          })
          setRows(trackerRows)

          setTotal(response.count!)
          setLoading(false)
        } else {
          setLoading(false)
          enqueueSnackbar(
            response.message ??
              'Ocorreu um erro interno. Comunique o administrador do sistema!',
            {
              variant: 'error'
            }
          )
        }
      } catch (error: any) {
        setLoading(false)
        enqueueSnackbar(
          'Ocorreu um erro interno. Comunique o administrador do sistema!',
          {
            variant: 'error'
          }
        )
      }
    }
  }

  useEffect(() => {
    listTrackers()
  }, [archived, filter, page, rowsPerPage, openContract, currentContract])

  return (
    <Wrapper className="container">
      <Grid
        modelTrackerColumns={true}
        archiveColumn={false}
        permissions={{
          canAdd: isImpersonate,
          canEdit: isImpersonate,
          canArchive: false
        }}
        valueFilter={filter}
        archive={archived}
        columns={columns}
        rows={rows ?? []}
        totalRows={total ?? 0}
        loading={loading}
        toolTipAddRow="Adicionar modelo do rastreador"
        toolTipArchive="Arquivar modelo do rastreador"
        toolTipEditRow="Editar modelo do rastreador"
        toolTipUnarchive="Desarquivar modelo do rastreador"
        searchPlaceHolder="Pesquisar por modelo"
        page={page}
        rowsPerPage={rowsPerPage}
        paginationMode="server"
        handlePageChange={(nextPage: number) => {
          setPage(nextPage)
        }}
        handleRowPerPageChange={(nextRowsPerPage: number) => {
          setRowsPerPage(nextRowsPerPage)
        }}
        onGridAction={(action, row) => handleActions(action, row as Row)}
        onFilterChange={(searchFilter) => setFilter(searchFilter)}
        initialState={{
          sorting: {
            sortModel: [{ field: 'Codigo', sort: 'asc' }]
          }
        }}
        sx={{
          '.MuiDataGrid-colCellTitle': {
            display: 'block',
            textAlign: 'center',
            width: '100%'
          }
        }}
      />
      <ModelTracker
        isChange={changeTracker}
        tracker={trackerChange ?? null}
        open={openContract}
        close={() => setOpenContract(false)}
      />
      <HistoryModelTracker
        isChange={changeHistory}
        tracker={trackerHistory!}
        open={openHistory}
        close={() => {
          setChangeHistory(false)
          setOpenHistory(false)
        }}
      />
    </Wrapper>
  )
}
export default ModelTrackers
