import { AxiosError } from 'axios'
import Response from '../api/Response'
import { ManagerAccount } from '../../types/ManagerAccount'
import api from './api'

export const listAllManagerAccount = async (): Promise<
  Response<ManagerAccount[]>
> => {
  try {
    const response = await api.get('Conta/ListarTodas')

    return Response.fromData(
      response.data.data as ManagerAccount[],
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
