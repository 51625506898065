import { Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Button, ModalBase } from '../index'

interface WarningModalExistProps {
  title?: string
  isOpen: boolean
  close: () => void
  archive: boolean
  routeRedirect: string
}

const WarningModalExist = ({
  title,
  isOpen,
  close,
  archive,
  routeRedirect
}: WarningModalExistProps) => {
  const navigate = useNavigate()

  const description = archive
    ? 'Este item já existe nos registros do sistema, não pode ser duplicado e está arquivado!'
    : 'Este item já existe nos registros do sistema e não pode ser duplicado.'

  const subDescription = archive
    ? 'Deseja desarquiva-lo e editar?'
    : 'Deseja editar este item?'

  const textButtonAccept = archive ? 'Desarquivar e editar' : 'Editar'

  return (
    <ModalBase
      title={title}
      open={isOpen}
      toggle={() => {
        close()
      }}
      fullWidth
      maxWidth={'sm'}
      footerBackground="warningPastel"
      footer={
        <Box display="flex" justifyContent="space-between" gap={1}>
          <Button
            color="gray"
            onClick={() => {
              close()
            }}
          >
            Cancelar
          </Button>
          <Button
            color="warning"
            type="submit"
            onClick={() => {
              navigate(`${routeRedirect}`)
              close()
            }}
          >
            {textButtonAccept}
          </Button>
        </Box>
      }
    >
      <Box className="container">
        <Typography variant="subtitle1" align="center">
          {description}
        </Typography>
        <Typography variant="subtitle2" align="center">
          {subDescription}
        </Typography>
      </Box>
    </ModalBase>
  )
}

export default WarningModalExist
