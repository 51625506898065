import { Box } from '@mui/system'
import styled, { css } from 'styled-components'
import { colors } from '../../assets/variables'

interface PropsBranch {
  active: boolean
  disabledCursor?: boolean
}

interface PropsBranches {
  show: boolean
}

interface StylesHamburguerProps {
  active: boolean
}

const hamburguerActive = css`
  background-color: ${colors.danger};
  span {
    background-color: white;
  }
  &::before,
  span {
    background-color: white;
    top: 50%;
    transform: rotate(45deg);
  }
  &::after {
    background-color: white;
    top: 50%;
    transform: rotate(-45deg);
  }
`
const hamburguerInactive = css`
  background-color: ${colors.grayPastel};
  span {
    background-color: ${colors.grayLight};
  }
  &::before {
    background-color: ${colors.grayLight};
    top: 8px;
  }
  &::after {
    background-color: ${colors.grayLight};
    bottom: 8px;
  }
`

export const Wrapper = styled.div`
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid ${colors.borderColor};
  z-index: 999;
`

export const Hamburguer = styled.div<StylesHamburguerProps>`
  position: relative;
  height: 30px;
  min-height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  margin-right: 1rem;
  cursor: pointer;
  ${(props: StylesHamburguerProps) =>
    props.active ? hamburguerActive : hamburguerInactive}
  &::before,
  ::after,
  span {
    content: '';
    position: absolute;
    transition: 0.3s;
    height: 2px;
    width: calc(100% - 10px);
  }
`

export const Title = styled.h1`
  font-size: 18px;
  color: ${colors.grayLight};
  padding-left: 10px;
  margin-left: 10px;
  border-left: solid 1px ${colors.borderColor};
  @media (max-width: 567px) {
    font-size: 12px;
  }
`
export const User = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  user-select: none;
  padding-right: 1rem;
  position: relative;
  cursor: pointer;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.5rem;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.textColor};
    display: none;
    @media (min-width: 576px) {
      display: block;
    }
  }

  small {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    font-size: 12px;
    font-weight: 500;
    color: ${colors.gray400};
    display: none;
    @media (min-width: 576px) {
      display: block;
    }
  }
`

const showBranches = css`
  visibility: visible;
  opacity: 1;
`

export const Branches = styled.div<PropsBranches>`
  background-color: white;
  border: 1px solid ${colors.borderColor};
  border-radius: 0.3rem;
  transition: 0.1s;
  visibility: hidden;
  overflow: unset;
  opacity: 1;
  ${(props: PropsBranches) => (props.show ? showBranches : null)}
`

export const Branch = styled(Box)<PropsBranch>`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin: 8px 0;
  margin-top: 0rem;
  background-color: ${(props: PropsBranch) =>
    props.active ? colors.primaryPastel : colors.grayPastel};
  width: 240px;
  border-radius: 0.3rem;
  cursor: pointer;
  &:nth-last-child(1) {
    margin-bottom: 0.5rem;
  }
  span {
    line-clamp: 2;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(220px - 1rem);
    font-size: 14px;
    font-weight: 500;
    color: ${(props: PropsBranch) =>
      props.active ? colors.primary : colors.grayLight};
  }
  small {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(220px - 1rem);
    font-size: 12px;
    font-weight: 400;
    color: ${(props: PropsBranch) =>
      props.active ? colors.primary : colors.grayLight};
  }
`

export const UserImpersonate = styled.div`
  background-color: ${colors.primaryPastel};
  height: calc(50px - 1px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  border-left: 1px solid ${colors.borderColor};
  cursor: pointer;
  svg {
    color: ${colors.primary};
    height: 15px;
    width: 15px;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -8px -8px;
  background-color: ${colors.grayPastel};
  border-top: 1px solid ${colors.borderColor};
  border-radius: 0 0 0.3rem 0.3rem;
  padding: 1rem;
`
