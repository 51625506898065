import { intervalToDuration } from 'date-fns'

export const compareDates = (
  startExecutionDate: string,
  endExecutionDate?: string
) => {
  let comparationEndDate = new Date()
  const executionStart = new Date(startExecutionDate)
  if (endExecutionDate) {
    comparationEndDate = new Date(endExecutionDate)
  }

  const { hours = 0, minutes = 0 } = intervalToDuration({
    start: executionStart,
    end: comparationEndDate
  })
  return `${hours! < 10 ? 0 : ''}${hours}:${minutes! < 10 ? 0 : ''}${minutes}`
}
