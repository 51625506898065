import { PermissionProps } from '../types/AccessProfile'
import { PagePermissionProps } from '../types/PagePermission'

const getScreenPermissionByScreenName = (
  screenName: string,
  userActionsSystem: PermissionProps[] | []
) => {
  const permissions = userActionsSystem
    ?.map((item: any) => {
      if (item.tela === screenName) {
        return item.identificador
      }
      return null
    })
    .filter((elem: any) => {
      return elem !== null
    })
  return {
    havePermission: !!permissions?.length,
    canAdd: permissions?.some((item: string) => item === 'cadastrar'),
    canEdit: permissions?.some((item: string) => item === 'alterar'),
    canArchive: permissions?.some((item: string) => item === 'arquivar')
  } as PagePermissionProps
}

export default getScreenPermissionByScreenName
