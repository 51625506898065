export const contrastPallete = [
  ['0268ff', 'fe01c9', '69e500', 'ff4800', '2bf6fa'],
  ['f0e500', , '01b778', '0899ba'],
  ['2d6a4f', '0f80aa', 'ff6d00', '5a189a', '538d22', '04a6c2'],
  ['ff7900', '7b2cbf', 'a71e34', '73a942', '0899ba'],
  ['ff8500', '9d4edd', '0f80aa', 'ff9100', 'bd1f36', '16679a'],
  ['ff9e00', 'c71f37', '1a5b92', 'da1e37', '1c558e', 'e01e37']
]
export const pallete = [
  ['00b2ca', '74c69d', 'DA64ED', '641220', 'ff4800', '3fc1c0', '1d4e89'],
  ['52b788', '04a6c2', 'ff5400', '240046', '6e1423', '1a4301', '20bac5'],
  ['40916c', '0899ba', 'ff6000', '3c096c', '85182a', '245501', '00b2ca'],
  ['2d6a4f', '0f80aa', 'ff6d00', '5a189a', 'a11d33', '538d22', '04a6c2'],
  ['1b4332', '16679a', 'ff7900', '7b2cbf', 'a71e34', '73a942', '0899ba'],
  ['ff8500', '9d4edd', '001233', '0f80aa', 'ff9100', 'bd1f36', '16679a'],
  ['ff9e00', 'c71f37', '1a5b92', 'da1e37', '1c558e', 'e01e37'],
  [
    '03071e',
    '370617',
    'b21e35',
    '9d0208',
    'd00000',
    'dc2f02',
    'e85d04',
    'f48c06',
    'faa307',
    'ffba08'
  ],
  ['03045e', '023e8a', '0077b6', '0096c7', '00b4d8', '48cae4'],
  ['74c69d', '52b788', '40916c', '2d6a4f', '1b4332', '081c15'],
  [
    '006466',
    '065a60',
    '0b525b',
    '144552',
    '1b3a4b',
    '212f45',
    '272640',
    '312244',
    '3e1f47',
    '4d194d'
  ],
  [
    '0466c8',
    '0353a4',
    '023e7d',
    '002855',
    '001845',
    '6a040f',
    '33415c',
    '5c677d',
    '7d8597',
    '979dac'
  ],
  ['ff7aa2', 'e05780', 'b9375e', '8a2846', '602437', '522e38'],
  [
    'b76935',
    'a56336',
    '935e38',
    '815839',
    '6f523b',
    '5c4d3c',
    '4a473e',
    '38413f',
    '263c41',
    '143642'
  ]
]
