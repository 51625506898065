import { AxiosError } from 'axios'
import Response from '../api/Response'
import { listPaginationQuery } from '../../types/Global'
import { User, UserList } from '../../types/User'
import api from './api'

export const listUserByCompanyId = async (
  managerAccountId: number
): Promise<Response<UserList[]>> => {
  try {
    const response = await api.get(
      `Account/BuscarUsuariosImpersonate?CodigoContaSelecionada=${managerAccountId}`
    )

    return Response.fromData(
      response.data.data as UserList[],
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

interface CreateConfigImportProps {
  nomeTela: string
  campos: FieldsConfigImportProps[]
}

interface FieldsConfigImportProps {
  nomeCampo: string
  valorSelecionado: string
  dataInclusao?: string
}

export const listAllUsers = async ({
  codigoContrato,
  arquived = false,
  orderName = 'Nome',
  orderDirection = 'asc',
  skip = 0,
  take = 20,
  query = ''
}: listPaginationQuery): Promise<Response<User[]>> => {
  try {
    const iscodigoContrato = !!codigoContrato

    const params = `${
      iscodigoContrato ? `CodigoContrato=${codigoContrato}&` : ''
    }ordenacao[0].ordenacao=${orderName}&ordenacao[0].direcaoOrdenacao=${orderDirection}
        &Arquivados=${arquived}
        &Skip=${skip}
        &Take=${take}
        &Query=${query}
      `.trim()

    const response = await api.get(`Usuario/Listar?${params}`)

    return Response.fromDataPagination(
      response.data.data as User[],
      response.status,
      response.data.campos,
      response.data.count,
      response.data.ordenacaoAtual,
      response.data.count,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const readDetails = async (codigo: string): Promise<Response<User>> => {
  try {
    const response = await api.get(`Usuario/DetalhesUsuarios?Codigo=${codigo}`)

    return Response.fromData(
      response.data.data as User,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const searchConfigImport = async (
  screen: string
): Promise<Response<FieldsConfigImportProps[]>> => {
  try {
    const response = await api.get(
      `Usuario/BuscarConfiguracaoImportacao?NomeTela=${screen}`
    )

    return Response.fromData(
      response.data.data as FieldsConfigImportProps[],
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const saveConfigImport = async (
  data: CreateConfigImportProps
): Promise<Response<any[]>> => {
  try {
    const response = await api.post(
      `Usuario/SalvarConfiguracaoImportacao`,
      data
    )

    return Response.fromData(
      response.data.data as any[],
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const getUserHavePermissionModelTracker = async (): Promise<
  Response<any[]>
> => {
  try {
    api.interceptors.request.use(
      (config) => {
        const tokenImpersonate = localStorage.getItem(
          '@lutocar-token-impersonate'
        )

        config.headers['Authorization'] = tokenImpersonate
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    const response = await api.get(`Usuario/PermissaoConfiguracaoRastreador`)

    return Response.fromData(
      response.data.data as any[],
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
