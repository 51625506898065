import { lazy } from 'react'

import { PDFViewer } from '@react-pdf/renderer'
const ReturnButton = lazy(() => import('./ReturnButton'))

interface GenerationReportPdfI {
  fileName: string
  setDocumentPdf: React.Dispatch<any>
  setGenerationPdf: React.Dispatch<React.SetStateAction<boolean>>
  documentPdf: any
  onClick?: () => void
  setLoading?: (value: boolean) => void
  isDifferenceDate?: boolean
}

function GenerationReportPdf({
  setDocumentPdf,
  setGenerationPdf,
  documentPdf,
  onClick
}: GenerationReportPdfI) {
  if (typeof documentPdf === 'string') {
    return (
      <>
        <ReturnButton
          setDocumentPdf={setDocumentPdf}
          setGenerationPdf={setGenerationPdf}
          onClick={onClick}
        />
        {documentPdf && (
          <iframe
            src={documentPdf}
            width="100%"
            height="1200vh"
            style={{ minHeight: '500px' }}
          >
            Seu navegador não suporta iframes.
          </iframe>
        )}
      </>
    )
  } else {
    return (
      <>
        <ReturnButton
          setDocumentPdf={setDocumentPdf}
          setGenerationPdf={setGenerationPdf}
          onClick={onClick}
        />
        {documentPdf && (
          <PDFViewer
            className="pdfViewer"
            showToolbar={true}
            width="100%"
            height="1200vh"
          >
            {documentPdf}
          </PDFViewer>
        )}
      </>
    )
  }
}

export default GenerationReportPdf
