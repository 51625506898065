import { lazy, useEffect, useState } from 'react'

import { Wrapper, User, Info, Branches, Footer } from './styles'

import ListMenuConfig from '../../utils/menu.json'

const Avatar = lazy(() => import('../Avatar/index'))
const Button = lazy(() => import('../Button/index'))
const ChangePassword = lazy(() => import('../ChangePassword/index'))
const ImpersonateModal = lazy(() => import('../ImpersonateModal/index'))

import Menu from '../Menu'
import Popover from '@mui/material/Popover'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import {
  AllContractsType,
  selectAllContract,
  selectCurrentContract,
  setAllContracts,
  setCurrentContract
} from '../../app/slices/contractSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  logout,
  selectUser,
  selectUserIsCityHall,
  userIsImpersonate
} from '../../app/slices/authSlice'
import { Box, Typography } from '@mui/material'
import { Contract } from '../../types/Contract'
import { useAppVersion } from '../../utils/useAppVersion'
import { Header as HeaderComponent } from '@inlog/react-components/dist/components/Header'
import SelectBranch from '../SelectBranch'

export const Header = () => {
  const version = useAppVersion()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useAppSelector(selectUser)
  const isImpersonate = useAppSelector(userIsImpersonate)
  const allContracts = useAppSelector(selectAllContract)
  const isCityHall = useAppSelector(selectUserIsCityHall)
  const currentContractStorage = localStorage.getItem(
    '@lutocar-current-contract'
  )
  const currentContract = useAppSelector(selectCurrentContract)
  const dispatch = useAppDispatch()

  const [titlePage, setTitlePage] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const [showMenu, setShowMenu] = useState(false)
  const [showAllContracts, setShowAllContracts] = useState(false)
  const [isHome, setIsHome] = useState(false)
  const [modals, setModals] = useState({
    impersonate: false,
    changePassword: false
  })

  useEffect(() => {
    const checkContracts = async () => {
      try {
        if (!allContracts) {
          const response = JSON.parse(
            localStorage.getItem('@lutocar-user-info')!
          )

          if (response) {
            dispatch(setAllContracts(response.contratos))

            if (currentContractStorage && !currentContract) {
              dispatch(
                setCurrentContract(
                  JSON.parse(currentContractStorage) as Contract
                )
              )
            }
          }
        }
      } catch (e) {}
    }

    checkContracts()
  }, [allContracts])

  useEffect(() => {
    if (location.pathname == '/') {
      setIsHome(true)
      setShowMenu(true)
      setTitlePage('')
    } else {
      setIsHome(false)
      setShowMenu(false)
      ListMenuConfig.find((menu) => {
        menu.items.find((item) => {
          if (item.attributes.path === location.pathname.split('/')[1]) {
            if (item.attributes.allContracts) {
              setShowAllContracts(true)
            } else {
              setShowAllContracts(false)
              if (currentContract?.codigo === 0 || currentContract === null) {
                allContracts !== null &&
                  dispatch(
                    setCurrentContract(
                      allContracts?.find((contract) => contract.matriz) ??
                        allContracts[0]
                    )
                  )
              }
            }
            setTitlePage(item.attributes.title)
            return
          }
        })
      })
      if (allContracts) {
        const currentContractAsType = currentContract as Contract
        const isCompanyDealership =
          currentContractAsType?.conta?.tipoConta === 'Concessionaria'
        if (
          location.pathname.includes('sweeping') ||
          location.pathname === '/sweep-special-services'
        ) {
          if (isCityHall) {
            const isCityHallContracts = allContracts?.filter((item) => {
              if (
                location.pathname.includes('sweeping') ||
                location.pathname === '/sweep-special-services'
              ) {
                if (isCityHall && item.conta?.tipoConta === 'Concessionaria') {
                  return item
                }
              } else if (isCityHall && item.conta?.tipoConta === 'Prefeitura') {
                return item
              } else if (
                !isCityHall &&
                item.conta?.tipoConta === 'Concessionaria'
              ) {
                return item
              }
            })
            if (isCityHallContracts.length) {
              if (!isCompanyDealership && currentContractAsType.codigo !== 0) {
                dispatch(
                  setCurrentContract(
                    isCityHallContracts?.find((contract) => contract.matriz) ??
                      isCityHallContracts[0]
                  )
                )
              }
            } else {
              dispatch(
                setCurrentContract({
                  codigo: 0,
                  razaoSocial: 'Todos os contratos',
                  chaveGoogle: '',
                  raioVarricaoVelocidade: [],
                  identificadorAutenticador: ''
                } as AllContractsType)
              )
            }
          }
        } else {
          if (isCityHall) {
            if (isCompanyDealership) {
              dispatch(setCurrentContract(allContracts[0]))
            }
          } else {
            if (!isCompanyDealership) {
              dispatch(setCurrentContract(allContracts[0]))
            }
          }
        }
      }
    }
  }, [location.pathname, allContracts])

  const [dropdown, setDropdown] = useState(true)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Wrapper>
        <HeaderComponent
          logoPros={{ alt: 'Lutocar' }}
          onMenuClick={() => !isHome && setShowMenu((prev) => !prev)}
          hideMenuButton={isHome}
          pageTitle={titlePage ? `Lutocar - ${titlePage}` : `Lutocar`}
          mobileBreakpoint={768}
          CenterComponent={
            <SelectBranch
              showAllContracts={showAllContracts}
              isCityHall={isCityHall}
            />
          }
          isImpersonate={isImpersonate}
          handleImpersonateClick={() =>
            setModals((prevModals) => {
              return { ...prevModals, impersonate: true }
            })
          }
          RightActionsComponent={
            <div className="d-flex align-items-center">
              <User
                id="id-user-content"
                onClick={(e: any) => {
                  setDropdown(!dropdown)
                  handleClick(e)
                }}
              >
                <Info>
                  <span>{user?.nome ?? ''}</span>
                </Info>
                <Avatar fullName={user?.nome?.toString()} />
              </User>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <Branches show={open}>
                  <Box
                    style={{
                      margin: '0px',
                      overflow: 'unset',
                      height: '101px'
                    }}
                  >
                    <Footer
                      style={{
                        flexDirection: 'column',
                        width: '200px',
                        height: '100px',
                        overflow: 'unset'
                      }}
                    >
                      <Box
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: !isImpersonate
                            ? 'space-between'
                            : 'flex-end'
                        }}
                      >
                        <Button
                          id="logout"
                          color="error"
                          onClick={async () => {
                            dispatch(logout())
                            navigate('/login')
                          }}
                        >
                          Sair
                        </Button>
                      </Box>
                      <Box>
                        <Typography align="center" fontSize={12}>
                          Versão: {version}
                        </Typography>
                      </Box>
                      <ChangePassword
                        open={modals.changePassword}
                        close={() => {
                          setModals({ ...modals, changePassword: false })
                        }}
                      />
                    </Footer>
                  </Box>
                </Branches>
              </Popover>

              <ImpersonateModal
                open={modals.impersonate}
                close={() => {
                  setModals({ ...modals, impersonate: false })
                }}
              />
            </div>
          }
        />
      </Wrapper>
      <Menu show={showMenu} toggle={() => setShowMenu(false)} />
    </>
  )
}

export default Header
