import { AxiosError } from 'axios'
import Response from '../api/Response'
import { Contract, EditContractProps } from '../../types/Contract'
import { ArchiveProps, listPaginationQuery } from '../../types/Global'
import {
  ChangeTrackerProps,
  CreateTrackerProps,
  Tracker
} from '../../types/Tracker'
import api from './api'
import { SweepOverviewPoint } from '../../types/SweepOverview'

export const listAllTrackers = async ({
  codigoContrato = null,
  arquived = false,
  orderName = 'Codigo',
  orderDirection = 'asc',
  skip = 0,
  take = 20,
  query = ''
}: listPaginationQuery): Promise<Response<Tracker[]>> => {
  try {
    const iscodigoContrato = !!codigoContrato
    const params = `
      ${
        iscodigoContrato ? `CodigoContrato=${codigoContrato}&` : ''
      }ordenacao[0].ordenacao=${orderName}&ordenacao[0].direcaoOrdenacao=${orderDirection}
      &Arquivados=${arquived}
      &Skip=${skip}
      &Take=${take}
      &Query=${query}
    `.trim()

    const response = await api.get(`Rastreador/Listar?${params}`)

    return Response.fromDataPagination(
      response.data.data as Tracker[],
      response.status,
      response.data.campos,
      response.data.count,
      response.data.ordenacaoAtual,
      response.data.count,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const archiveTracker = async (
  props: ArchiveProps
): Promise<Response<any>> => {
  try {
    const response = await api.put('Rastreador/Arquivar', props)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const unarchiveTracker = async (
  props: ArchiveProps
): Promise<Response<any>> => {
  try {
    const response = await api.put('Rastreador/Desarquivar', props)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const createTracker = async (
  params: CreateTrackerProps
): Promise<Response<Contract | any>> => {
  try {
    const response = await api.post(`Rastreador/Salvar`, params)

    return Response.fromData(
      response.data.data as Contract | any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const updateTracker = async (params: {
  codigo: number
  codigoModeloRastreador: number
}): Promise<Response<Contract | any>> => {
  try {
    const response = await api.put(`Rastreador/Alterar`, params)

    return Response.fromData(
      response.data.data as Contract | any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const changeTracker = async (
  params: ChangeTrackerProps
): Promise<Response<Contract | any>> => {
  try {
    const response = await api.post(`Rastreador/TrocarImei`, params)

    return Response.fromData(
      response.data.data as Contract | any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const editContract = async (
  params: EditContractProps
): Promise<Response<Contract>> => {
  try {
    const response = await api.put(`Contrato/Alterar`, params)

    return Response.fromData(
      response.data.data as Contract,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const readHistoryByTrackerCode = async (
  trackerCode: number,
  startDate: string,
  endDate: string,
  executionCode?: number
): Promise<Response<SweepOverviewPoint[]>> => {
  try {
    const isExecutionCode = !!executionCode

    const params = `${
      isExecutionCode ? `?CodigoSetorExecucao=${executionCode}&` : '?'
    }CodigoRastreador=${trackerCode}&DataInicial=${startDate}&DataFinal=${endDate}
    `.trim()

    const response = await api.get(`Rastreador/BuscarHistorico${params}`)

    return Response.fromData(
      response.data.data as SweepOverviewPoint[],
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
