import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as DatePickerUI } from '@mui/x-date-pickers/DatePicker'
import { Control, Controller, FieldError } from 'react-hook-form'
import { FormHelperText } from '@mui/material'
// import Input from '../InputBase'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'

export interface DatePickerProps {
  name: string
  label: string
  className?: string
  loading?: boolean
  disabled?: boolean
  control: Control<any>
  errorContent?: FieldError
  size?: 'small' | 'medium' | 'large' | undefined
  rules?: any
  maxDate?: Date
}

const DatePicker = ({
  name,
  label,
  className = 'mt-3',
  control,
  errorContent,
  disabled,
  rules,
  maxDate
}: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <DatePickerUI
              {...field}
              disabled={disabled}
              label={label}
              className={className}
              value={field.value}
              onChange={field.onChange}
              maxDate={maxDate}
              slotProps={{
                textField: {
                  error: !!fieldState.error,
                  helperText: fieldState.error ? fieldState.error.message : '',
                  InputLabelProps: { shrink: true },
                  disabled: disabled,
                  InputProps: {
                    style: {
                      cursor: disabled ? 'not-allowed' : 'default'
                    }
                  }
                }
              }}
              format="dd/MM/yyyy"
            />
            {errorContent && (
              <FormHelperText error={!!errorContent?.message}>
                {errorContent?.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePicker
