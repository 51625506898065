import React from 'react'
import { SpinnerLoading } from '../index'
import { MapArea, Wrapper, Loading } from './styles'

interface MapProps {
  id: string
  style?: object
  loadingShapes?: boolean
}

const Map: React.FC<MapProps> = ({ id, loadingShapes, style }) => {
  return (
    <Wrapper>
      <MapArea style={style} id={id}></MapArea>
      {loadingShapes && (
        <Loading>
          <SpinnerLoading size="md" />
          <div className="ms-3">Carregando...</div>
        </Loading>
      )}
    </Wrapper>
  )
}

export default Map
