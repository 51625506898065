/* eslint-disable @typescript-eslint/no-shadow */
import { AxiosError } from 'axios'

class Response<T = undefined> {
  data?: T
  campos?: T
  count?: number
  ordenacaoAtual?: T
  take?: number
  statusCode?: number
  success?: boolean
  message?: string
  error?: Error

  static fromDataPagination<T>(
    data: T,
    statusCode: number,
    campos?: T,
    count?: number,
    ordenacaoAtual?: T,
    take?: number,
    message?: string,
    success?: boolean
  ): Response<T> {
    const response = new Response<T>()

    response.data = data
    response.success = success
    response.statusCode = statusCode
    response.message = message
    response.take = take
    response.ordenacaoAtual = ordenacaoAtual
    response.count = count
    response.campos = campos

    return response
  }

  static fromData<T>(
    data: T,
    statusCode: number,
    message?: string,
    success?: boolean
  ): Response<T> {
    const response = new Response<T>()
    response.data = data
    response.success = success
    response.statusCode = statusCode
    response.message = message

    return response
  }

  static fromError<T>(error: AxiosError): Response<T> {
    const response = new Response<T>()
    response.error = error

    if (error.response) {
      response.statusCode = error.response.status
      response.message = error.message
    } else {
      response.statusCode = 500
      response.message =
        'Ocorreu um erro que não foi possível ser tratado, tente novamente mais tarde.'
    }

    return response
  }
}

export default Response
