import styled from 'styled-components'
import { colors } from '../../assets/variables'

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Input = styled.input`
  appearance: none;
  background-color: white;
  width: 16px;
  height: 16px;
  border: 1px solid ${colors.borderColor};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: ${colors.primary};
  }
  &:checked {
    border-color: ${colors.primary};
    background-color: ${colors.primary};
    &::before {
      transform: scale(1);
      background-color: white;
    }
  }
`

export const Text = styled.span`
  font-size: 14px;
  color: ${colors.gray900};
  padding: 0 0.5rem;
  user-select: none;
`
