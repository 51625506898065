import * as yup from 'yup'

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('E-mail obrigatório')
    .email('E-mail inválido')
    .matches(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      'E-mail inválido'
    ),
  senha: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No minimo 6 caracteres')
})

export const stepsForgotPasswordSchema = [
  yup.object().shape({
    email: yup
      .string()
      .required('E-mail obrigatório')
      .email('E-mail inválido')
      .matches(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        'E-mail inválido'
      )
  }),
  yup.object().shape({
    tokenValidacao: yup
      .string()
      .required('Digite o código enviado ao seu e-mail!')
      .min(4, 'O código deve ter 4 caracteres!')
      .max(4, 'O código deve ter 4 caracteres!')
  }),
  yup.object().shape({
    novaSenha: yup
      .string()
      .required('Nova senha é obrigatória')
      .min(6, 'No minimo 6 caracteres'),
    confirmarNovaSenha: yup
      .string()
      .required('Confirmação de senha é obrigatório')
      .oneOf([yup.ref('novaSenha')], 'As senhas tem que ser iguais')
  })
]

export const impersonateFormSchema = yup.object().shape({
  conta: yup
    .object()
    .shape({
      label: yup.string().required('Conta é obrigatório'),
      value: yup.number().notOneOf([-1], 'Conta é obrigatório').required()
    })
    .nullable()
    .required('Conta é obrigatório'),
  usuario: yup
    .object()
    .shape({
      label: yup.string().required('Usuário é obrigatório'),
      value: yup.number().notOneOf([-1], 'Usuário é obrigatório').required()
    })
    .nullable()
    .required('Usuário é obrigatório')
})
