// import { addDays } from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export const utcToLocal = (dateTime: Date, fmt = 'yyyy-MM-ddTHH:mm:ss') => {
  return format(zonedTimeToUtc(dateTime, 'UTC'), fmt, {
    timeZone: 'UTC'
  })
}

export const localToUtc = (dateTime: Date, fmt = 'yyyy-MM-ddTHH:mm:ss') => {
  return format(utcToZonedTime(dateTime, 'UTC'), fmt, {
    timeZone: 'UTC'
  })
}
export const checkInitialAndFinalDate = (
  dateTime: Date,
  dateTimeFinal: Date
) => {
  if (dateTime.getDay() === dateTimeFinal.getDay()) {
    // Verifica se a hora final é menor do que a hora inicial
    if (dateTime.getHours() > dateTimeFinal.getHours()) {
      return true
    }
  }

  return false
}

export const combineDateAndTime = (date: string, time: Date) => {
  const [year, month, day] = date.split('-').map(Number)

  return new Date(
    year,
    month - 1,
    day,
    time.getHours(),
    time.getMinutes(),
    time.getSeconds()
  )
}

export const utcToLocalTime = (time: string, fmt = 'HH:mm:ss') => {
  const localTime = format(new Date(), `yyyy-MM-dd ${time}`)
  return format(zonedTimeToUtc(localTime, 'UTC'), fmt, {
    timeZone: 'UTC'
  })
}

export const localTimeToUtc = (time: string, fmt = 'HH:mm:ss') => {
  const localTime = format(new Date(), `yyyy-MM-dd ${time}`)
  return format(utcToZonedTime(localTime, 'UTC'), fmt, {
    timeZone: 'UTC'
  })
}
