import { Box, Typography } from '@mui/material'
import { ProgressPercent, TooltipCustomization, Wrapper } from './styles'

interface ProgressProps {
  progress: number[]
  maxAreaConsidereComplet: number
}

const Progress = ({ progress, maxAreaConsidereComplet }: ProgressProps) => {
  const getTextByProgress = (progressItem: number) => {
    let progressText = ''
    if (progressItem < 10) {
      progressText = ``
    } else {
      progressText = `${progressItem}%`
    }
    return (
      <Typography variant="caption" alignSelf={'center'}>
        {progressText}
      </Typography>
    )
  }

  const subTotal = Number(progress[0]) + Number(progress[1])
  const toComplete = Number.isNaN(100 - Number(subTotal))
    ? 0
    : 100 - Number(subTotal)
  const isCompleteSector = subTotal >= Number(maxAreaConsidereComplet)

  return (
    <Wrapper>
      {progress[0] === 0 && progress[1] === 0 ? (
        <TooltipCustomization colorTwo="red" title={`0%`} placement="top-start">
          <ProgressPercent percent={1} color="red">
            <Box left={25} position="relative" color="black">
              <Typography variant="caption" alignSelf={'center'}>
                0%
              </Typography>
            </Box>
          </ProgressPercent>
        </TooltipCustomization>
      ) : (
        <>
          <TooltipCustomization
            colorTwo={isCompleteSector ? 'greenStrong' : 'green'}
            title={`${progress[0]}%`}
            placement="top-end"
          >
            {progress[0] !== 0 ? (
              <ProgressPercent
                percent={progress[0]}
                color={isCompleteSector ? 'greenStrong' : 'green'}
              >
                {getTextByProgress(progress[0])}
              </ProgressPercent>
            ) : (
              <></>
            )}
          </TooltipCustomization>
          <TooltipCustomization
            colorTwo="yellow"
            title={`${progress[1]}%`}
            placement="top-end"
          >
            {progress[1] !== 0 ? (
              <ProgressPercent
                percent={progress[1] === 0 ? 0 : progress[1]}
                color={'yellow'}
              >
                {getTextByProgress(progress[1])}
              </ProgressPercent>
            ) : (
              <></>
            )}
          </TooltipCustomization>
          <TooltipCustomization
            colorTwo="red"
            title={`${Number(toComplete.toFixed(2))}%`}
            placement="top-end"
          >
            {toComplete !== 0 ? (
              <ProgressPercent
                percent={Number(toComplete.toFixed(2))}
                color="red"
              />
            ) : (
              <></>
            )}
          </TooltipCustomization>
        </>
      )}
    </Wrapper>
  )
}

export default Progress
