import * as React from 'react'
import { Contract } from '../../types/Contract'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  removeCurrentContract,
  selectAllContract,
  selectCurrentContract,
  setCurrentContract
} from '../../app/slices/contractSlice'
import { Box } from '@mui/material'
import CompanySelect from '../Header/companySelect'

interface SelectBranchI {
  showAllContracts: boolean
  isCityHall: boolean
}
const SelectBranch = ({ showAllContracts, isCityHall }: SelectBranchI) => {
  const currentContract = useAppSelector(selectCurrentContract)
  const allContracts = useAppSelector(selectAllContract)
  const dispatch = useAppDispatch()

  const [allContractsFiltered, setAllContractsFiltered] = React.useState<
    Contract[] | null
  >(null)
  const checkContractsAccess = () => {
    const modifyContracts = allContracts

    const modifyContractsFiltered: Contract[] | any = modifyContracts?.filter(
      (item) => {
        const isCompanyDealership = item.conta?.tipoConta === 'Concessionaria'
        if (
          location.pathname.includes('sweeping') ||
          location.pathname === '/sweep-special-services'
        ) {
          if (isCityHall) {
            if (isCompanyDealership) {
              return item
            }
          } else {
            if (isCompanyDealership) {
              return item
            }
          }
        } else {
          if (isCityHall) {
            if (!isCompanyDealership) {
              return item
            }
          } else {
            if (isCompanyDealership) {
              return item
            }
          }
        }
        return null
      }
    )

    if (showAllContracts) {
      modifyContractsFiltered?.unshift({
        codigo: 0,
        razaoSocial: 'Todos os contratos',
        cnpj: 'Remover filtro por contrato',
        identificadorAutenticador: '',
        codigoConta: 0,
        fusoHorario: 0,
        status: '',
        tamanhoFragmentoTrecho: 0,
        tempoParadoParaDesligarGps: 0,
        tempoSegundosRegistrarParada: 0,
        velocidadeLimiteGerarPontoDeslocamento: 0,
        velocidadeMaximaLutocarParaVinculacao: 0,
        codigoOperadoraTelefonia: 0,
        deltaTempoFechamentoTurnoMinutos: 0,
        distanciaMaximaQueima: 0,
        distanciaMaximaVincular: 0,
        ativarVinculacaoComplementar: false,
        velocidadeMaximaConsiderarPontosValidosVincComplementar: 0,
        ativarVinculacaoRapida: false,
        numeroMinimoPontosVinculacaoRapida: 0,
        distanciaMinimaEntrePontosVinculadosVincRapida: 0,
        velocidadeMaximaLutocarParaVinculacaoRapida: 0,
        distanciaMaximaVincularVincRapida: 0,
        numeroMinimoPontosVincComplementar: 0,
        distanciaMinimaEntrePontosVinculados: 0,
        distanciaParaGerarPontoDeslocamento: 0,
        metrosVarricaoPorMinutoTrechoAmarelo: 0,
        metrosVarricaoPorMinutoTrechoVerde: 0,
        numeroMinimoPontosVinculacao: 0,
        percentualBateriaAlerta: 0,
        percentualBateriaCritica: 0,
        percentualMinimoConsiderarSetorVarrido: 0,
        velocidadeMaximaExpurgo: 0,
        habilitarExpurgo: false,
        numeroDiasParaEleiminarRastreadoresAutoVinculados: 0,
        prefeiturasVisibilidade: [],
        habilitarServicosEspeciais: false,
        matriz: false,
        delayPosicaoGps: 0,
        raioVarricaoVelocidade: [],
        salvarSetorDistanciaMinimaEntrePontos: 0,
        salvarSetorAnguloMinimoEntrePontos: 0
      })
    }

    setAllContractsFiltered(modifyContractsFiltered)
  }

  React.useEffect(() => {
    checkContractsAccess()
  }, [currentContract, showAllContracts])

  const handleChangeContract = async (selectedContract: string) => {
    const contract = allContractsFiltered?.find(
      (filterContract: Contract) =>
        `${filterContract.codigo}` === selectedContract
    )

    if (contract) {
      if (contract.codigo === 0) {
        dispatch(removeCurrentContract())
      } else {
        dispatch(setCurrentContract(contract))
      }
    }
  }

  return (
    <Box style={{ margin: '8px' }}>
      <CompanySelect
        companies={allContractsFiltered}
        selectedCompany={currentContract as Contract}
        handleChange={(company) => {
          handleChangeContract(String(company?.codigo))
        }}
      />
    </Box>
  )
}

export default SelectBranch
