import React, { ReactNode, useRef } from 'react'
import { Wrapper } from './styles'

interface ScrollBarProps {
  className?: string
  maxHeight?: string
  onBottomPercent?: () => void
  children: ReactNode
}

const ScrollBar: React.FC<ScrollBarProps> = ({
  className,
  maxHeight,
  onBottomPercent,
  children
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (Math.floor(((scrollTop + clientHeight) / scrollHeight) * 100) === 100) {
      if (onBottomPercent) {
        onBottomPercent()
      }
    }
  }
  return (
    <Wrapper
      className={className}
      ref={scrollRef}
      onScroll={onScroll}
      style={{ maxHeight: maxHeight ?? 'initial' }}
    >
      {children}
    </Wrapper>
  )
}

export default ScrollBar
