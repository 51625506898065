import React from 'react'
import logo from '../../assets/images/logo-inlog.png'
import { StylesSpinnerProps } from './styles'
import { Box, CircularProgress, Typography } from '@mui/material'

const GlobalLoading: React.FC<StylesSpinnerProps> = ({}) => {
  return (
    <Box
      sx={{ p: 2 }}
      className="container d-flex flex-column justify-content-center align-items-center vh-100"
    >
      <CircularProgress />
      <div className="d-flex align-items-center mb-2 gap-3">
        <img src={logo} alt="" width="100" />
        <Typography sx={{ margin: '0px 10px 0px 10px' }} variant="h5">
          |
        </Typography>
        <Typography variant="h5">Lutocar</Typography>
      </div>
    </Box>
  )
}

export default GlobalLoading
