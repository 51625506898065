import { List, Box } from '@mui/material'
import styled from 'styled-components'
import styledEmotion from '@emotion/styled'

import { Wrapper as GridArea } from '../../components/Grid/styles'
import { colors } from '../../assets/variables'

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${GridArea} {
    height: 100%;
  }
`

export const ListImei = styledEmotion(List)({
  width: '100%',
  maxWidth: 1500,
  bgcolor: 'background.paper',
  position: 'relative',
  overflow: 'auto',
  maxHeight: 500
})

export const BoxImei = styledEmotion(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  alignItems: 'center',
  gap: '1rem',
  bgcolor: 'background.paper',
  border: '1px solid',
  borderColor: colors.gray200,
  borderRadius: '4px',
  overflowY: 'auto',
  paddingRight: '15px'
})
