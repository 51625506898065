import styled, { css } from 'styled-components'
import { colors } from '../../assets/variables'

export interface StylesCloseProps {
  size?: string
}

const size = {
  sm: css`
    width: 18px;
    height: 18px;
  `,
  md: css`
    width: 22px;
    height: 22px;
  `,
  lg: css`
    width: 28px;
    height: 28px;
  `,
  xl: css`
    width: 32px;
    height: 32px;
  `
}

export const Wrapper = styled.button<{ size?: string }>`
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 60%;
    height: 60%;
    color: ${colors.danger};
  }
  &:hover {
    background-color: ${colors.danger};
    svg {
      color: white;
    }
  }
  ${(props: StylesCloseProps) =>
    size[(props.size as keyof typeof size) ?? 'md']}
  &:disabled {
    cursor: not-allowed;
    svg {
      color: ${colors.gray};
    }
    &:hover {
      background-color: transparent;
      svg {
        color: ${colors.gray};
      }
    }
  }
`
