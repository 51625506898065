import styled from 'styled-components'

export const Wrapper = styled.div``

export const StyledGridToolbarItemContainer = styled.div`
  width: 100%;
  align-items: center;
`

export const StyledSearchToolbar = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
`
