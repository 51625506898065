import styled from 'styled-components'
import { colors } from '../../assets/variables'

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dadada;
    border-radius: 8px;
    border: 1px solid ${colors.grayPastel};
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background: ${colors.grayPastel};
  }
`
