import styled, { css } from 'styled-components'
import { colors } from '../../assets/variables'

export interface StylesSpinnerProps {
  size?: string
}

const size = {
  sm: css`
    width: 15px;
    height: 15px;
    border: 2px solid ${colors.grayPastel};
  `,
  md: css`
    width: 20px;
    height: 20px;
    border: 3px solid ${colors.grayPastel};
  `,
  lg: css`
    width: 25px;
    height: 25px;
    border: 4px solid ${colors.grayPastel};
  `,
  xl: css`
    width: 30px;
    height: 30px;
    border: 5px solid ${colors.grayPastel};
  `
}

export const Wrapper = styled.span<StylesSpinnerProps>`
  ${(props: StylesSpinnerProps) =>
    size[(props.size as keyof typeof size) ?? 'md']};
  border-left-color: ${colors.primary};
  border-radius: 50%;
  background: transparent;

  animation-name: rotate-s-loader;
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
  margin-bottom: -4px;
  @keyframes rotate-s-loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
