import { format } from 'date-fns'

const formatSpecialServiceShift = (
  timeStartShift: string,
  timeEndShift: string
) => {
  const startTime = timeStartShift.split(':')
  const dateStartTime = new Date()
  dateStartTime.setHours(parseInt(startTime[0], 10))
  dateStartTime.setMinutes(parseInt(startTime[1], 10))
  dateStartTime.setSeconds(parseInt(startTime[2], 10))

  const endTime = timeEndShift.split(':')
  const dateEndTime = new Date()
  dateEndTime.setHours(parseInt(endTime[0], 10))
  dateEndTime.setMinutes(parseInt(endTime[1], 10))
  dateEndTime.setSeconds(parseInt(endTime[2], 10))

  const startTimeFormat = format(dateStartTime, 'HH:mm')
  const endTimeFormat = format(dateEndTime, 'HH:mm')

  return {
    startTimeFormat,
    endTimeFormat
  }
}
export default formatSpecialServiceShift
