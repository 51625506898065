import styledEmotion from '@emotion/styled'
import { colors } from '../../assets/variables'
import SelectAutoComplete from '../SelectAutoComplete'

export const StyledSelectAutoComplete = styledEmotion(SelectAutoComplete)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-disabled fieldset': {
      borderColor: colors.gray200,
      color: colors.grayDisabled
    }
  },
  '& .MuiInputLabel-root': {
    '&.Mui-disabled': {
      color: colors.grayDark
    }
  },
  '& .Mui-disabled': {
    borderColor: colors.gray800,
    backgroundColor: colors.grayDisabled,
    cursor: 'not-allowed'
  },
  input: {
    ':disabled': {
      backgroundColor: colors.grayDisabled,
      opacity: '1',
      cursor: 'not-allowed',
      pointerEvents: 'all'
    }
  }
})
