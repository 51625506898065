import { AxiosError } from 'axios'
import Response from '../api/Response'
import { ArchiveProps, listPaginationQuery } from '../../types/Global'
import { Region } from '../../types/Region'
import api from './api'
import { SpecialServiceT } from '../../types/SpecialService'

interface createRegionData {
  codigoContrato: number
  identificador: string
  descricao: string
}

interface editRegionData {
  codigo: number
  codigoContrato: number
  identificador: string
  descricao: string
}

export const listAllSpecialServices = async ({
  codigoContrato,
  arquived = false,
  orderName = 'CodigoContrato',
  orderDirection = 'asc',
  skip = 0,
  take = 20,
  query = ''
}: listPaginationQuery): Promise<Response<SpecialServiceT[]>> => {
  try {
    const iscodigoContrato = !!codigoContrato

    const params = `${
      iscodigoContrato ? `CodigoContrato=${codigoContrato}&` : ''
    }ordenacao[0].ordenacao=${orderName}&ordenacao[0].direcaoOrdenacao=${orderDirection}
        &Arquivados=${arquived}
        &Skip=${skip}
        &Take=${take}
        &Query=${query}
      `.trim()

    const response = await api.get(`ServicosEspeciais/Listar?${params}`)

    return Response.fromDataPagination(
      response.data.data as Region[],
      response.status,
      response.data.campos,
      response.data.count,
      response.data.ordenacaoAtual,
      response.data.count,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const archiveSpecialService = async (
  props: ArchiveProps
): Promise<Response<any>> => {
  try {
    const response = await api.put('ServicosEspeciais/Arquivar', props)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const unarchiveSpecialService = async (
  props: ArchiveProps
): Promise<Response<any>> => {
  try {
    const response = await api.put('ServicosEspeciais/Desarquivar', props)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const createSpecialService = async (
  params: createRegionData
): Promise<Response<any>> => {
  try {
    const response = await api.post('ServicosEspeciais/Salvar', params)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const editSpecialService = async (
  params: editRegionData
): Promise<Response<any>> => {
  try {
    const response = await api.put('ServicosEspeciais/Alterar', params)

    return Response.fromData(
      response.data.data as any,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}

export const readDetails = async (
  code: string
): Promise<Response<SpecialServiceT>> => {
  try {
    const response = await api.get(`ServicosEspeciais/Detalhes?Codigo=${code}`)

    return Response.fromData(
      response.data.data as SpecialServiceT,
      response.status,
      response.data.message,
      response.data.success
    )
  } catch (error) {
    return Response.fromError(error as AxiosError)
  }
}
