import styled, { css } from 'styled-components'
import { Wrapper as Grid } from '../../components/Grid/styles'

export const Window = styled.div`
  position: absolute;
  height: 80vh;
  background-color: white;
`

export const MapContainer = styled.div`
  position: relative;
  transition: 0.5s;
`

export const GridContainer = styled.div`
  position: relative;
  transition: 0.5s;
  ${Grid} {
    height: 100%;
  }
`

export const FilterContainer = styled.div`
  background-color: white;
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 15px;
`

const showInfo = css`
  overflow: hidden;
  width: 34px;
  height: 34px;
  padding: 0;
  border-radius: 50%;
`

export const ViewDetails = styled.div<{ showInfo: boolean }>`
  position: absolute;
  display: block;
  right: 10px;
  top: 10px;
  background-color: white;
  opacity: 0.9;
  border-radius: 0.3rem;
  padding: 15px;
  padding-top: 5px;
  width: 395px;
  max-height: calc(100vh - 185px);
  overflow-y: auto;
  z-index: 99;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1) !important;
  ${(props: { showInfo: boolean }) => (!props.showInfo ? showInfo : null)}
  max-width: 95%;
`

export const HeadFilter = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 240px;
  overflow-y: auto;
  padding-left: 15px;
`
export const ListFilter = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 300px;
  max-width: 360px;
  flex-wrap: wrap;
  overflow-y: auto;
`
const bigger = css`
  ${MapContainer} {
    height: 20%;
  }
  ${GridContainer} {
    height: calc(80% - 160px);
    @media (min-width: 768px) {
      height: calc(80% - 64px);
    }
  }
`

const normal = css`
  ${MapContainer} {
    height: 50%;
  }
  ${GridContainer} {
    height: calc(50% - 160px);
    @media (min-width: 768px) {
      height: calc(50% - 64px);
    }
  }
`

const small = css`
  ${MapContainer} {
    height: 80%;
  }
  ${GridContainer} {
    height: calc(20% - 62px);
  }
`
const onlyMap = css`
  ${MapContainer} {
    height: 100%;
  }
  ${GridContainer} {
    height: calc(0% - 160px);
    @media (min-width: 768px) {
      height: calc(0% - 64px);
    }
  }
`

const onlyGrid = css`
  ${MapContainer} {
    height: 0%;
  }
  ${GridContainer} {
    height: calc(100% - 160px);
    @media (min-width: 768px) {
      height: calc(100% - 64px);
    }
  }
`

const area = [onlyMap, small, normal, bigger]

export const Wrapper = styled.div<{ resizeArea: number; renderMap: boolean }>`
  height: calc(100vh - 50px);
  ${(props: { resizeArea: number; renderMap: boolean }) =>
    props.renderMap ? area[props.resizeArea - 1] : onlyGrid}
`
