import { useEffect } from 'react'
import { Html5Qrcode } from 'html5-qrcode'
import { ModalBase } from '../index'
import { useSnackbar } from 'notistack'
const brConfig = { fps: 10, qrbox: { width: 500, height: 150 } }

interface CodeScannerProps {
  isOpen: boolean
  close: () => void
  sendValue: (code: string) => void
}

const CodeScanner = ({
  isOpen = false,
  close,
  sendValue
}: CodeScannerProps) => {
  let html5Qrcode: Html5Qrcode | null

  const { enqueueSnackbar } = useSnackbar()

  const handleClose = async () => {
    await html5Qrcode?.stop()
    close()
  }

  const qrCodeSuccessCallback = async (decodedText: any) => {
    sendValue(decodedText)
    handleClose()
  }

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        html5Qrcode = new Html5Qrcode('reader')

        Html5Qrcode.getCameras()
          .then((devices) => {
            // Para implementar mais devices listar e deixar usuario escolher
            if (devices && devices.length) {
              // const cameraId = devices[0].id
            }
          })
          .catch(() => {
            enqueueSnackbar(
              'Para escanear é necessário habilitar a permissão',
              {
                variant: 'warning'
              }
            )
            close()
          })

        html5Qrcode.start(
          // CameraID
          { facingMode: 'environment' },
          brConfig,
          qrCodeSuccessCallback,
          () => null
        )
      }, 1000)
    }
  }, [isOpen])

  return (
    <ModalBase
      open={isOpen}
      title="Escaneie o código de barras"
      toggle={() => {
        handleClose()
      }}
      fullWidth
      maxWidth={'md'}
      footer={
        <div className="d-flex justify-content-between align-items-center"></div>
      }
    >
      <>
        <div id="reader" style={{ width: '100%' }} />
      </>
    </ModalBase>
  )
}

export default CodeScanner
