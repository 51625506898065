// CompanySelect.tsx
import React, { useState } from 'react'
import { Button, Menu, MenuItem, Typography, ListItemText } from '@mui/material'
import { Contract } from '../../types/Contract'
import { AllContractsType } from '../../app/slices/contractSlice'
import { makeStyles } from '@mui/styles'

interface CompanySelectProps {
  companies: Contract[] | null
  selectedCompany: Contract | null
  handleChange: (company: Contract | AllContractsType | null) => void
}

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    border: 'none',
    background: '#fff',
    minWidth: '200px'
  },
  menuListItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '4px',
    margin: '4px 4px'
  },
  selected: {
    backgroundColor: 'rgb(217, 238, 245)',
    minWidth: '200px',
    paddingTop: '0px',
    borderRadius: '6px'
  },
  menu: {
    marginTop: '5px',
    paddingTop: '0px',
    borderRadius: '6px'
  },
  menuItem: {
    paddingTop: '0px'
  },
  menuList: {
    paddingTop: '0px',
    paddingBottom: '0px',
    margin: '4px 4px'
  }
})

const CompanySelect: React.FC<CompanySelectProps> = ({
  companies,
  selectedCompany,
  handleChange
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (company: Contract | AllContractsType | null) => {
    handleChange(company)
    handleClose()
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        className={classes.button}
      >
        <Typography>
          {selectedCompany?.razaoSocial ?? 'Todos os contratos'}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        MenuListProps={{
          classes: { padding: classes.menuList }
        }}
      >
        {Array.isArray(companies) &&
          companies.map((company) => (
            <MenuItem
              key={company?.cnpj}
              onClick={() => handleMenuItemClick(company)}
              className={
                selectedCompany?.cnpj === company?.cnpj
                  ? classes.selected
                  : classes.menuItem
              }
            >
              <ListItemText
                primary={company?.razaoSocial}
                secondary={company?.cnpj}
                className={classes.menuListItem}
              />
            </MenuItem>
          ))}
      </Menu>
    </div>
  )
}

export default CompanySelect
